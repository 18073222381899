import { useEffect } from 'react'
import { AnimationControls, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

type UseInViewAnimationReturn = [
  (node?: Element | null | undefined) => void,
  AnimationControls,
]

export const useInViewAnimation = (): UseInViewAnimationReturn => {
  const controls = useAnimation()
  const { ref, inView } = useInView({
    threshold: 0.3,
  })

  useEffect(() => {
    if (inView) {
      controls.start((i) => {
        return {
          y: 0,
          opacity: 1,
          transition: { delay: i * 0.15, duration: 1 },
        }
      })
    }
  }, [inView])

  return [ref, controls]
}

import React from 'react'
import { isEmpty, noop } from 'lodash'
import { useRouter } from 'next/router'
import NextLink from 'next/link'

const Link = (props) => {
  const {
    children = noop,
    path = '',
    hash = '',
    to,
    displayPath,
    query,
  } = props
  const router = useRouter()

  const isCurrentPage = router.pathname === path

  const scrollTo = (e) => {
    e.preventDefault()

    const element = document.querySelector(hash)

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })

      window.history.pushState(
        {},
        '',
        isEmpty(displayPath) ? `${path}${hash}` : displayPath,
      )
    }
  }

  return (
    <NextLink
      href={
        !isEmpty(to) ? to : displayPath || { pathname: path, hash: hash, query }
      }
      passHref
      shallow
    >
      {children({
        onClick: isCurrentPage && !isEmpty(hash) ? scrollTo : undefined,
      })}
    </NextLink>
  )
}

export default Link

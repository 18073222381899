export const SITE_REFERENCE = [
  {
    src: '/images/Landing/site-references/cotto-logo.png',
    alt: 'cotto logo',
    width: 141,
    height: 36,
  },
  {
    src: '/images/Landing/site-references/TOG-logo.png',
    alt: 'TOG logo',
    width: 141,
    height: 58,
  },
  {
    src: '/images/Landing/site-references/Pioneer-logo.png',
    alt: 'Pioneer logo',
    width: 182,
    height: 18,
  },
  {
    src: '/images/Landing/site-references/Apai-logo.png',
    alt: 'Apai logo',
    width: 141,
    height: 100,
  },
  {
    src: '/images/Landing/site-references/Zortout-logo.png',
    alt: 'Zortout logo',
    width: 141,
    height: 50,
  },
  {
    src: '/images/Landing/site-references/Atimedesign-logo.png',
    alt: 'Atimedesign logo',
    width: 141,
    height: 50,
  },
  {
    src: '/images/Landing/site-references/DECR-logo.png',
    alt: 'DECR logo',
    width: 133,
    height: 36,
  },
]

import { object, string, boolean } from 'yup'

export const checkWebsiteSchema = object().shape({
  website: string().url('กรุณากรอกเว็บไซต์ให้ถูกต้อง').required(),
  email: string().email().required(),
  tel: string()
    .required('กรุณาระบุเบอร์โทรติดต่อของคุณ')
    .matches(/^[0-9]+$/, 'กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก')
    .length(10, 'กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก'),
  terms_and_policy_consent: boolean().required(),
  marketing_consent: boolean(),
})

import React from 'react'
import Image from 'next/image'
import { Grid, Box } from '@chakra-ui/react'

import { Link } from '@/lib/Link'
import { mq } from '@/lib/responsive'
import { logEvent } from '@/lib/stats/gtm'
import { Container, H2, P, Button } from '@/components/Landing/shared'

const content = [
  {
    image: '/images/Landing/sc-3-hosting.svg',
    title: 'เราโฮสต์ Policy ให้คุณ',
    description:
      'หมดกังวลหากกฎหมายเปลี่ยนแปลง เราโฮสต์นโยบายให้คุณฟรี พร้อมอัปเดตให้สอดคล้องตามกฎหมาย',
  },
  {
    image: '/images/Landing/sc-3-laws.svg',
    title: 'หมดกังวลเรื่องกฎหมาย',
    description:
      'เรามีผู้เชี่ยวชาญด้านกฎหมายที่จะช่วยตอบข้อสงสัยเกี่ยวกับ PDPA ตลอดชั่วโมงทำการ',
  },
  {
    image: '/images/Landing/sc-3-ease-to-use.svg',
    title: 'ใช้ง่ายกับเว็บไซต์',
    description:
      'ไม่ต้องเสียเวลาเขียนโค้ด เพราะ Policy รูปแบบ HTML ของเราสามารถนำไปใช้ให้เข้ากับแบรนด์ของคุณได้ทันที',
  },
  {
    image: '/images/Landing/sc-3-dashboard.svg',
    title: 'จัดการทุก Policy ในที่เดียว',
    description:
      'เรามี Dashboard ส่วนตัวให้คุณเข้ามาจัดการ และทำการอัปเดต Policy ที่คุณสร้างได้ทุกที่ทุกเวลา',
  },
  {
    image: '/images/Landing/sc-3-worth-it.svg',
    title: 'คุ้มค่า คุ้มราคา',
    description:
      'ได้ Privacy Policy สอดคล้องกับ PDPA ทั้งฉบับภาษาไทยและภาษาอังกฤษฟรี! ไม่เสียค่าใช้จ่ายเพิ่มเติม',
  },
  {
    image: '/images/Landing/sc-3-business.svg',
    title: 'สอดคล้องกับธุรกิจของคุณ',
    description:
      'คุณเท่านั้นที่เข้าใจธุรกิจของคุณได้อย่างลึกซึ้ง เราจึงสร้าง Policy จากข้อมูลของคุณเพื่อผลลัพธ์ที่ดีที่สุด',
  },
]

const WhyUs = () => {
  return (
    <section
      css={{
        padding: '82px 13px 70px 13px',
        backgroundColor: '#f6f6f6',
        [mq('sm')]: {
          padding: '104px 40px',
        },
      }}
      id="WhyPDPAPro"
    >
      <Container css={{ textAlign: 'center' }}>
        <Grid templateColumns="1fr" rowGap="56px">
          <Box>
            <H2 css={{ fontSize: 32, [mq('sm')]: { fontSize: 40 } }}>
              ทำไมต้องเลือก PDPA Pro
            </H2>
            <P>
              PDPA Solution จาก PDPA Pro คือเครื่องมือสร้าง Privacy Policy
              ถูกต้องตาม PDPA
              <br />
              นำไปใช้ได้จริง ประหยัดเวลา และไม่ต้องจ้างนักกฎหมาย
            </P>
          </Box>
          <Grid
            gap={30}
            css={{
              [mq('sm')]: {
                gridTemplateColumns: '1fr 1fr',
              },
            }}
          >
            {content.map(({ image, title, description }, index) => (
              <Grid
                key={index}
                css={{
                  textAlign: 'center',
                  rowGap: 12,

                  [mq('sm')]: {
                    textAlign: 'left',
                  },
                  [mq('md+')]: {
                    gridAutoFlow: 'column',
                    gridTemplateColumns: '60px 1fr',
                    columnGap: 24,
                  },
                }}
              >
                <Box
                  minW={60}
                  css={{
                    margin: '0 auto',
                    [mq('sm')]: {
                      margin: 0,
                    },
                  }}
                >
                  <Image
                    objectFit="cover"
                    src={image}
                    alt={title}
                    width={60}
                    height={48}
                  />
                </Box>
                <Box>
                  <p css={{ fontSize: 24 }}>{title}</p>
                  <p css={{ fontSize: 20, fontWeight: 300 }}>{description}</p>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid gridAutoFlow="column" justifyContent="center" columnGap="24px">
            <Link route="selectPolicy" passHref>
              <Button
                onClick={() => {
                  logEvent({
                    ga: {
                      category: 'Landing Page',
                      action: 'Click create policy',
                    },
                  })
                  logEvent({
                    ga: {
                      category: 'Landing Page',
                      action: 'Click create policy why us',
                    },
                  })
                }}
              >
                สร้าง Policy ตอนนี้!
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default WhyUs

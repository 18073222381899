import { VStack, Text } from '@chakra-ui/react'

interface Props {
  count: number | string
}

function PolicyCount({ count }: Props): React.ReactElement {
  return (
    <VStack
      spacing="2"
      sx={{
        mt: '60px',
        mb: '40px',
        pt: '80px',
        pb: '100px',
        backgroundImage: 'url("/images/Landing/policy-count-bg.svg")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
      }}
    >
      <Text sx={{ fontSize: '32px', verticalAlign: 'center' }}>{count}</Text>
      <Text sx={{ fontSize: '2xl' }}>
        กว่า&nbsp;
        <Text as="span" sx={{ color: 'primary.400' }}>
          Policy
        </Text>
        &nbsp;ที่ถูกสร้างกับเรา
      </Text>
    </VStack>
  )
}

export default PolicyCount

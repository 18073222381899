import React, { useState, useEffect, useRef, Fragment } from 'react'
import { get } from 'lodash'
import { useRouter } from 'next/router'
import { Icon, Flex, Box, Center } from '@chakra-ui/react'
import { PhoneIcon } from '@chakra-ui/icons'

import { Link as CustomLink } from '@/features/home/components'
import { Container, ExternalLink } from '@/components/Landing/shared'
import { Link } from '@/lib/Link'
import { useAuthContext } from '@/lib/auth/AuthContext'
import { mq, useMQ } from '@/lib/responsive'
import { logEvent } from '@/lib/stats/gtm'

import { FacebookIcon } from '@/lib/styles/customIcons'
import env from '@/config/env'

import { ReactComponent as PrivacyIcon } from '@/svg/header/privacy.svg'
import { ReactComponent as HRPrivacyIcon } from '@/svg/header/hr-privacy.svg'
import { ReactComponent as CookieIcon } from '@/svg/header/cookie.svg'
import { ReactComponent as CCTVIcon } from '@/svg/header/cctv.svg'
import { ReactComponent as RecruitmentIcon } from '@/svg/header/recruitment.svg'
import { ReactComponent as VendorIcon } from '@/svg/header/vendor.svg'
import { ReactComponent as DPAIcon } from '@/svg/header/dpa.svg'
import { ReactComponent as ConsultingIcon } from '@/svg/header/consulting.svg'

import { NavItem, SocialButton, headerCommonProps } from './share.style'
import SubMenus from './SubMenus'

const menus = [
  {
    label: 'ทำไมต้องเรา',
    anchor: '#WhyPDPAPro',
    path: '/',
    hash: '#WhyPDPAPro',
  },
  {
    label: 'บริการและราคา',
    anchor: '#OurServices',
    path: '/',
    hash: '#OurServices',
    subMenus: [
      {
        image: PrivacyIcon,
        route: 'servicePrivacy',
        label: 'Privacy Policy',
      },
      {
        image: HRPrivacyIcon,
        route: 'serviceHRPrivacy',
        label: 'HR Privacy Policy',
      },
      {
        image: CookieIcon,
        route: 'serviceCookie',
        label: 'Cookie Policy',
      },
      {
        image: CCTVIcon,
        route: 'serviceCCTV',
        label: 'CCTV Policy',
      },
      {
        image: RecruitmentIcon,
        route: 'serviceRecruitment',
        label: 'Recruitment Policy',
      },
      {
        image: VendorIcon,
        route: 'serviceVendor',
        label: 'Vendor Policy',
      },
      {
        image: DPAIcon,
        route: 'serviceDPA',
        label: 'Data Processing Agreement',
      },
      {
        image: ConsultingIcon,
        path: '/',
        hash: '#OurServices',
        anchor: '#OurServices',
        label: 'Consulting Service',
        query: {
          service: 'consult',
        },
      },
    ],
  },
  {
    label: 'บริการอื่น ๆ ของเรา',
    to: '/our-products',
  },
  {
    label: 'สนับสนุน',
    subMenus: [
      {
        label: 'FAQ',
        to: '/faq',
      },
      {
        label: 'Release note',
        to: '/release-notes',
      },
    ],
  },
  {
    label: 'Blog',
    anchor: 'blogs',
    to: '/blogs',
  },
  {
    label: 'เข้าสู่ระบบ',
    anchor: 'login',
    to: '/login',
  },
]

const Header = (props) => {
  const { isAltStyle = false } = props

  const [altStyle, setAltStyle] = useState(isAltStyle)
  const [show, setShow] = useState(false)

  // const submenusDisclosure = useDisclosure()

  const { isAuth } = useAuthContext()
  const router = useRouter()

  const { isMobile, isTablet, isDesktop } = useMQ()

  const scrollTO = useRef()

  const handleScroll = () => {
    window.clearTimeout(scrollTO.current)

    scrollTO.current = window.setTimeout(() => {
      const hero = document.querySelector('#Hero')

      if (hero) {
        const { top } = hero.getBoundingClientRect()

        setAltStyle(Math.abs(top) > 10)
      }
    }, 10)
  }

  useEffect(() => {
    if (!isMobile) {
      setShow(false)
    }
  }, [isMobile])

  useEffect(() => {
    const scrollingElement = document.scrollingElement

    if (!scrollingElement) return

    if (show) {
      scrollingElement.style['overflow-y'] = 'hidden'
    } else {
      scrollingElement.style['overflow-y'] = 'auto'
    }
  }, [show])

  const renderMenuNav = () => {
    return (
      <Fragment>
        {menus.map((menu, idx) => {
          if (isAuth && menu.anchor === 'login') {
            return (
              <Link route="dashboardPolicies" key={idx}>
                <NavItem altStyle={altStyle}>Dashboard</NavItem>
              </Link>
            )
          }

          if (menu.subMenus) {
            return <SubMenus menu={menu} altStyle={altStyle} key={idx} />
          }

          return (
            <CustomLink
              to={menu.to}
              path={menu.path}
              hash={menu.hash}
              key={idx}
            >
              {() => (
                <NavItem className="nav-item" altStyle={altStyle}>
                  {menu.label}
                </NavItem>
              )}
            </CustomLink>
          )
        })}
        <div css={{ height: '100%', display: 'flex' }}>
          <ExternalLink href="https://www.facebook.com/pdpapro">
            <SocialButton
              altStyle={altStyle}
              onClick={() =>
                logEvent({
                  ga: {
                    category: 'Landing Page',
                    action: 'Click facebook',
                  },
                })
              }
            >
              <Icon as={FacebookIcon} color="#fff" boxSize={{ base: '22px' }} />
            </SocialButton>
          </ExternalLink>

          <a href="tel:020245560">
            <NavItem
              altStyle={altStyle}
              css={{
                height: '100%',
                width: '72px',
              }}
            >
              <Icon
                as={PhoneIcon}
                color={'inherit'}
                boxSize={{ base: '22px' }}
              />
            </NavItem>
          </a>
        </div>
      </Fragment>
    )
  }

  const renderMobileMenuPanel = () => {
    return (
      <Box
        sx={{
          width: '100%',
          backgroundColor: altStyle ? 'white' : 'transparent',
          display: 'grid',
          position: 'fixed',
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            w: '100vw',
            h: '100vh',
            backgroundColor: 'blackAlpha.400',
            backdropFilter: 'blur(16px)',
          }}
          onClick={() => {
            setShow(false)
          }}
        />
        <Box
          sx={{
            width: '100%',
            backgroundColor: altStyle ? 'white' : 'transparent',
            padding: '32px',
            display: 'grid',
            position: 'fixed',
            gap: '18px',
            top: '60px',
            zIndex: 10,
            borderBottom: `1px solid ${altStyle ? '#e8e8e8' : 'transparent'}`,
            maxHeight: 'calc(100vh - 60px)',
            overflowY: 'auto',
          }}
        >
          {menus.map((menu, idx) => {
            if (isAuth && menu.anchor === 'login') {
              return (
                <Link route="dashboardPolicies" key={idx}>
                  <NavItem altStyle={altStyle}>Dashboard</NavItem>
                </Link>
              )
            }

            return (
              <Fragment key={idx}>
                <CustomLink to={menu.to} path={menu.path} hash={menu.hash}>
                  {() => (
                    <NavItem
                      className="nav-item"
                      altStyle={altStyle}
                      css={{
                        borderRadius: '8px',
                        fontSize: '20px',

                        '&:last-of-type': {
                          padding: '8px 0',
                        },
                      }}
                    >
                      {menu.label}
                    </NavItem>
                  )}
                </CustomLink>
                {menu.subMenus?.map((m) => {
                  let render = (children) => children

                  if (m.route) {
                    render = (children) => {
                      return (
                        <Link key={m.route} route={m.route} passHref sx={{}}>
                          {children}
                        </Link>
                      )
                    }
                  } else {
                    render = (children) => {
                      return (
                        <CustomLink to={m?.to} passHref>
                          {() => children}
                        </CustomLink>
                      )
                    }
                  }
                  return render(
                    <NavItem
                      className="nav-item"
                      altStyle={altStyle}
                      css={{
                        fontSize: '14px',
                        my: 2,

                        [mq('md')]: {
                          my: 5,
                        },
                      }}
                    >
                      {m.label}
                    </NavItem>,
                  )
                })}
              </Fragment>
            )
          })}

          <Box sx={{ justifySelf: 'center' }}>
            <ExternalLink href="https://www.facebook.com/pdpapro">
              <SocialButton
                altStyle={altStyle}
                css={{
                  backgroundColor: '#3b5999',
                  width: '56px',
                  height: '56px',
                  borderRadius: '8px',
                }}
              >
                <Icon
                  as={FacebookIcon}
                  color="#fff"
                  boxSize={{ base: '22px' }}
                />
              </SocialButton>
            </ExternalLink>
          </Box>

          {isTablet && (
            <Box sx={{ justifySelf: 'center' }}>
              <a href="tel:020245560">
                <NavItem
                  altStyle={altStyle}
                  css={{
                    width: '56px',
                    height: '56px',
                    borderRadius: '8px',
                  }}
                >
                  <Icon
                    as={PhoneIcon}
                    color={altStyle ? '#2b2b2b' : '#ffffff'}
                    boxSize={{ base: '22px' }}
                  />
                </NavItem>
              </a>
            </Box>
          )}
        </Box>
      </Box>
    )
  }

  useEffect(() => {
    if (get(router, 'pathname') === '/') {
      window.addEventListener('scroll', handleScroll, { passive: true })
      handleScroll()
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (typeof window === 'undefined') {
    return null
  }

  return (
    <Fragment>
      <header
        css={{
          ...headerCommonProps(altStyle),
        }}
      >
        <Container
          css={{
            display: 'grid',
            height: '100%',
            gridTemplateColumns: 'repeat(2, max-content)',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: 'unset',
            marginLeft: '19px',

            [mq('sm')]: {
              maxWidth: 'unset',
            },

            [mq('md')]: {
              maxWidth: 1000,
              margin: '0 auto',
              height: '100%',
              gridTemplateColumns: '1fr repeat(6, max-content) 60px',
            },

            [mq('md+')]: {
              maxWidth: 1113,
            },

            [mq('lg')]: {
              gridTemplateColumns: '1fr repeat(6, max-content) 72px',
            },
          }}
        >
          <div>
            <CustomLink path="/" hash="#Hero" displayPath="/" passHref>
              {() => (
                <a
                  css={{
                    width: '135px',
                    height: '32px',
                    display: 'block',
                    cursor: 'pointer',
                    background: `no-repeat center/contain url(/images/logo${
                      altStyle ? '' : '-alt'
                    }.svg)`,
                    color: 'transparent',

                    [mq('md')]: {
                      width: '159px',
                      height: '43px',
                    },
                  }}
                >
                  pdpa logo
                </a>
              )}
            </CustomLink>
          </div>

          {(isMobile || isTablet) && (
            <Flex>
              {isMobile && (
                <a href="tel:020245560">
                  <SocialButton>
                    <Icon
                      as={PhoneIcon}
                      color={altStyle ? '#2b2b2b' : '#ffffff'}
                      size={{ base: '22px' }}
                    />
                  </SocialButton>
                </a>
              )}

              <button
                css={{
                  width: '60px',
                  height: '60px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: `inset 1px -1px 0 0 ${
                    altStyle ? '#e8e8e8' : '#2e5682'
                  }`,
                }}
                onClick={() => setShow((state) => !state)}
                aria-label="menu"
              >
                <span
                  css={{
                    width: '24px',
                    height: '18px',
                    background: `no-repeat center/contain url(/icons/hamburger-icon${
                      altStyle ? '-alt' : ''
                    }.svg)`,
                  }}
                ></span>
              </button>
            </Flex>
          )}

          {isDesktop && renderMenuNav()}
        </Container>
        <Banner />
      </header>

      {show && renderMobileMenuPanel()}
    </Fragment>
  )
}

export default Header

const Banner = () => {
  if (!env.SHOW_ALERT_BANNER || env.SHOW_ALERT_BANNER === 'false') return null

  return (
    <Center
      sx={{
        color: 'white',
        w: '100vw',
        backgroundColor: '#ff3939cc',
        minH: '40px',
        textAlign: 'center',
        py: {
          base: '12px',
          md: '4px',
          lg: 0,
        },
        br: {
          sm: {
            d: 'none',
          },
          md: {
            d: 'block',
          },
          lg: {
            d: 'none',
          },
        },
      }}
    >
      ประกาศปิดปรับปรุงระบบในวันที่ 28 เมษายน 2564 เวลา 00:00 น.- 00.30 น.
      <br />
      ซึ่งจะทำให้ผู้ใช้งานไม่สามารถใช้งาน PDPA Pro ได้ในเวลาดังกล่าว
      ขออภัยในความไม่สะดวก
    </Center>
  )
}

import { Box, Text, VStack, Circle, SimpleGrid } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react'

import { Container } from '@/components/Landing/shared'
import { Strong } from '../shared'
import { useTransition } from 'react-spring'
import { AnimatedBox } from '@/lib/springComponent'

const PDPAApplyDate = '2022-06-01'
const applyDate = dayjs(PDPAApplyDate)

export const isNotExceedDate = (): boolean => {
  const now = dayjs()

  return applyDate.diff(now, 'seconds') >= 0
}

const limitCountDown = (diff: number) => {
  return Math.max(diff, 0)
}

const getDateDiff = () => {
  const now = dayjs()

  return {
    days: limitCountDown(applyDate.diff(now, 'days')),
    hours: limitCountDown(applyDate.diff(now, 'hours')) % 24,
    minutes: limitCountDown(applyDate.diff(now, 'minutes')) % 60,
    seconds: limitCountDown(applyDate.diff(now, 'seconds')) % 60,
  }
}

export const Countdown = (): React.ReactElement => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.3,
  })

  const [dateDiff, setDateDiff] = useState(getDateDiff)

  useEffect(() => {
    if (!inView) return

    setDateDiff(getDateDiff)

    const interval = setInterval(() => {
      setDateDiff(getDateDiff)
    }, 1000)

    return () => clearInterval(interval)
  }, [inView])

  return (
    <Box
      as="section"
      sx={{
        py: {
          base: '48px',
          md: '120px',
        },
      }}
    >
      <Container>
        <Box
          ref={ref}
          sx={{
            borderRadius: '12px',
            boxShadow: '4px 4px 20px rgba(135, 135, 135, 0.25)',
            py: 9,
            px: {
              base: 8,
              md: 8,
            },
            w: {
              md: '636px',
              lg: '992px',
              xl: 'full',
            },
            mx: 'auto',
          }}
        >
          <Text
            sx={{
              textAlign: 'center',
              fontSize: {
                base: '20px',
                lg: '32px',
              },
              strong: {
                color: 'primary.400',
              },
            }}
          >
            <Strong>พรบ.คุ้มครองข้อมูลส่วนบุคคล</Strong>
            ของประเทศไทย กำลังจะบังคับใช้
            <br />
            <Strong>ภายในวันที่ 1 มิถุนายน 2565</Strong>
          </Text>

          <SimpleGrid
            sx={{
              gridTemplateColumns: 'repeat(7, max-content)',
              gridGap: { base: 2, md: 4, lg: 5 },
              alignItems: 'center',
              justifyContent: 'center',
              mt: {
                base: 6,
                md: 8,
              },
            }}
          >
            <Flipper digit={2} value={Math.max(dateDiff.days, 0)} />
            <Text sx={separatorStyle}>:</Text>
            <Flipper digit={2} value={Math.max(dateDiff.hours, 0)} />
            <Text sx={separatorStyle}>:</Text>
            <Flipper digit={2} value={Math.max(dateDiff.minutes, 0)} />
            <Text sx={separatorStyle}>:</Text>
            <Flipper digit={2} value={Math.max(dateDiff.seconds, 0)} />

            <Text sx={labelStyle}>วัน</Text>
            <Box />
            <Text sx={labelStyle}>ชั่วโมง</Text>
            <Box />
            <Text sx={labelStyle}>นาที</Text>
            <Box />
            <Text sx={labelStyle}>วินาที</Text>
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  )
}

const separatorStyle = {
  fontSize: { base: '24px', md: '48px', lg: '72px' },
  fontWeight: 'medium',
  color: 'gray.900',
  lineHeight: '1em',
}

const labelStyle = {
  fontSize: { base: 'sm', md: '2xl' },
  color: 'gray.900',
  textAlign: 'center',
  fontWeight: 'thin',
}

type FlipperProps = {
  value: number
  digit?: number
}

const Flipper = ({ digit = 2, value }: FlipperProps): React.ReactElement => {
  const _value = `${value}`.padStart(digit, '0')

  const transitions = useTransition(_value, {
    from: {
      transformTop: 'perspective(1000px) rotateX(0deg) translate3d(0, 0, 0px)',
      transformBottom: 'perspective(1000px) rotateX(180deg)',
      boxShadowTop: 'inset 0 0px 0px 0px #00000000',
      boxShadowBottom: 'inset 0 0px 0px 0px #00000000',
    },
    enter: {
      transformTop: 'perspective(1000px) rotateX(0deg) translate3d(0, 0, 1px)',
      transformBottom: 'perspective(1000px) rotateX(0deg)',
      boxShadowTop: 'inset 0 0px 0px 0px #00000000',
      boxShadowBottom: 'inset 0 0px 0px 0px #00000000',
    },
    leave: {
      transformTop:
        'perspective(1000px) rotateX(-180deg) translate3d(0, 0, 2px)',
      transformBottom: 'perspective(1000px) rotateX(0deg)',
      boxShadowTop: 'inset 0 -100px 16px -16px #000000AA',
      boxShadowBottom: 'inset 0 100px 16px -16px #000000AA',
    },
    config: { duration: 600 },
  })

  return (
    <VStack spacing="4">
      <Box
        sx={{
          minW: {
            base: '56px',
            md: '110px',
            lg: '170px',
          },
          h: {
            base: '56px',
            md: '100px',
            lg: '160px',
          },
          position: 'relative',
          color: '#00E3AB',
          fontSize: { base: '2xl', md: '5xl', lg: '72px' },
          fontWeight: 'bold',
          letterSpacing: { md: 4 },
          textAlign: 'center',
          flexFlow: 'column nowrap',
          p: { md: 6 },
          transformStyle: 'preserve-3d',
        }}
      >
        {transitions(
          ({ transformTop: transform, boxShadowTop: boxShadow }, item) => (
            <AnimatedBox
              style={{
                transform,
                boxShadow,
              }}
              sx={{
                borderRadius: {
                  base: 'md',
                  lg: 'lg',
                },
                justifyContent: 'flex-end',
                overflow: 'hidden',
                position: 'absolute',
                w: 'full',
                h: '50%',
                top: 0,
                left: 0,
                transformOrigin: 'bottom',
                bg: 'gray.900',
              }}
            >
              <Text
                sx={{
                  transform: 'translate3d(0, 50%, 0)',
                  w: 'full',
                  lineHeight: '50%',
                  color: '#00E3AB',
                }}
              >
                {item}
              </Text>
            </AnimatedBox>
          ),
        )}

        {transitions(
          (
            { transformBottom: transform, boxShadowBottom: boxShadow },
            item,
          ) => (
            <AnimatedBox
              style={{
                transform,
                boxShadow,
              }}
              sx={{
                borderRadius: {
                  base: 'md',
                  sm: 'md',
                  md: 'lg',
                },
                justifyContent: 'flex-start',
                overflow: 'hidden',
                position: 'absolute',
                w: 'full',
                h: '50%',
                bottom: 0,
                left: 0,
                transformOrigin: 'top',
                bg: 'gray.900',
              }}
            >
              <Text
                sx={{
                  transform: 'translate3d(0, -50%, 0)',
                  w: 'full',
                  lineHeight: '50%',
                }}
              >
                {item}
              </Text>
            </AnimatedBox>
          ),
        )}

        <Box
          className="decor"
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            w: 'full',
            transform: 'translate3d(0, -50%, 100px)',
            h: { base: 2, sm: 3, md: 4 },
            zIndex: 'docked',
          }}
        >
          <Circle
            sx={{
              position: 'absolute',
              bg: 'white',
              boxSize: { base: 2, sm: 3, md: 4 },
              top: '50%',
              transform: 'translate3d(-50%, -50%, 0)',
              left: 0,
            }}
          />
          <Circle
            sx={{
              position: 'absolute',
              bg: 'white',
              boxSize: { base: 2, sm: 3, md: 4 },
              top: '50%',
              transform: 'translate3d(50%, -50%, 0)',
              right: 0,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bg: 'white',
              w: 'full',
              h: '2px',
              top: '50%',
              transform: 'translate3d(0, -50%, 0)',
            }}
          />
        </Box>
      </Box>
    </VStack>
  )
}

import React from 'react'
import { Box } from '@chakra-ui/react'

import { mq, Mobile, MediumPlusDesktop, LargeDesktop } from '@/lib/responsive'

import {
  Container,
  H2,
  P,
  Colors,
  Background,
} from '@/components/Landing/shared'

const GetStarted = () => {
  const data = [
    {
      icon: 'icons-8-online-paint-tool.svg',
      head: '1. เลือก Policy',
      body: 'เลือก Policy ที่เหมาะสมและครอบคลุมกับธุรกิจของคุณ',
    },
    {
      icon: 'icons-8-online-paint-tool-copy.svg',
      head: '2. กรอกข้อมูลที่จำเป็น',
      body: 'กรอกข้อมูลเกี่ยวกับบริษัทของคุณ หากมีข้อสงสัยเรายินดีช่วยคุณทันที',
    },
    {
      icon: 'icons-8-upload-to-cloud.svg',
      head: '3.นำไปใช้ได้ทันที',
      body: 'คัดลอกลิงก์ไปใช้ได้เลยและกลับมาที่คลาวด์ได้ทุกเมื่อ',
    },
  ]

  return (
    <section
      css={{
        backgroundColor: '#0d1d2e',
        color: '#ffffff',
        padding: '46px 0 109px',
        position: 'relative',
        zIndex: -1,

        [mq('sm')]: {
          padding: '55px 0 78px',
        },

        [mq('lg')]: {
          padding: '90px 0 143px',
        },
      }}
    >
      <span
        id="GetStarted"
        css={{
          position: 'absolute',
          left: 0,
          top: '-30px',

          [mq('sm')]: {
            top: 0,
          },
          [mq('lg')]: {
            top: '-70px',
          },
        }}
      />
      <Container
        css={{
          textAlign: 'center',
        }}
      >
        <H2
          css={{
            fontSize: 33,
            lineHeight: '50px',

            [mq('sm')]: {
              fontSize: 40,
              marginBottom: '8px',
            },

            [mq('lg')]: {
              fontSize: 52,
              marginBottom: 0,
              position: 'relative',
            },
          }}
        >
          เริ่มต้นง่ายๆ{' '}
          <Mobile>
            <br />
          </Mobile>
          <span css={{ color: Colors.PRIMARY_DEFAULT }}>เพียง 3 ขั้นตอน</span>
          <MediumPlusDesktop>
            <Box
              position="absolute"
              w="71px"
              h="21px"
              opacity="0.2"
              css={{
                [mq('md+')]: {
                  top: 120,
                  left: 120,
                },
                [mq('lg')]: {
                  top: 14,
                  left: 60,
                },
              }}
            >
              <Background
                src="/images/Landing/svg-05.svg"
                objectFit="contain"
              />
            </Box>
          </MediumPlusDesktop>
          <LargeDesktop>
            <Box
              position="absolute"
              w="202px"
              h="215px"
              top="302px"
              left="-275px"
            >
              <Background
                src="/images/Landing/svg-04.svg"
                objectFit="contain"
              />
            </Box>
          </LargeDesktop>
        </H2>

        <P
          css={{
            marginBottom: '30px',

            [mq('sm')]: {
              marginBottom: '32px',
            },

            [mq('lg')]: {
              marginBottom: '72px',
            },
          }}
        >
          ไม่ต้องเตรียมเอกสาร ไม่ต้องใช้เวลานาน เพียงคลิกแล้วโหลด แค่นั้น
        </P>
        <div
          css={{
            display: 'grid',
            justifyContent: 'center',
            gap: '32px',

            [mq('sm')]: {
              gap: '12px',
              gridTemplateColumns: 'repeat(3, 232px)',
            },

            [mq('md+')]: {
              gridTemplateColumns: 'repeat(3, 354px)',
            },
          }}
        >
          {data.map(({ icon, head, body }) => (
            <div
              key={head}
              css={{
                display: 'grid',
                justifyContent: 'center',
                justifyItems: 'center',
                gridTemplateRows: '96px max-content',
                gap: '16px',
                color: '#e6e6e6',

                [mq('sm')]: {
                  gap: '24px',
                },

                [mq('lg')]: {
                  gap: '36px',
                },
              }}
            >
              <div
                css={{
                  position: 'relative',
                  width: '86px',
                  height: '96px',

                  [mq('sm')]: {
                    width: '111px',
                    height: '124px',
                  },

                  [mq('lg')]: {
                    width: '122px',
                    height: '124px',
                  },
                }}
              >
                <Background
                  src={`/images/Landing/${icon}`}
                  objectFit="contain"
                />
              </div>
              <div>
                <P
                  css={{
                    fontWeight: 500,
                    fontSize: 20,

                    [mq('md+')]: {
                      fontSize: 24,
                    },
                  }}
                >
                  {head}
                </P>
                <P
                  css={{
                    fontSize: 20,

                    [mq('md+')]: {
                      fontSize: 24,
                    },
                  }}
                >
                  {body}
                </P>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}

export default GetStarted

import { Flex, Heading, Link, Stack, Text } from '@chakra-ui/react'
import { Br } from '../components/shared'

export const REFERRAL_META = {
  title: 'PDPA Pro Referral Program | รับค่าคอมมิชชันทุกออเดอร์!',
  description:
    'สร้างรายได้กับเราง่าย ๆ เพียงแค่แชร์โค้ด รับค่าคอมมิชชันทันทีทุกยอดซื้อ ไม่มีขั้นต่ำ!',
} as const

export const WHAT_IS_REFERRALS = [
  {
    title: (
      <Heading as="h4">
        ลูกค้าได้&nbsp;
        <Text as="span" color="secondary.400">
          ส่วนลด
        </Text>
      </Heading>
    ),
    content: (
      <Text>
        ลูกค้าที่กรอก Referral code จะได้รับส่วนลดพิเศษ เมื่อซื้อผลิตภัณฑ์ของ
        PDPA Pro เป็นครั้งแรก
      </Text>
    ),
    img: '/images/Landing/referral/what-is-discount.svg',
  },
  {
    title: (
      <Heading as="h4">
        คุณได้&nbsp;
        <Text as="span" color="secondary.400">
          คอมมิชชัน
        </Text>
      </Heading>
    ),
    content: (
      <Text>
        สำหรับ Partner ที่เข้าร่วมโปรแกรมนี้
        <br />
        สามารถนำโค้ด Referral ไปโปรโมตในที่ต่าง ๆ และจะได้รับค่าคอมมิชชัน
        เมื่อลูกค้ากรอกโค้ดตอนชำระเงินครั้งแรก
      </Text>
    ),
    img: '/images/Landing/referral/what-is-commission.svg',
  },
]

export const REFERRAL_STEPS = [
  {
    title: 'สมัครโปรแกรม',
    content: (
      <Text>
        กรอกข้อมูลของคุณเพื่อสมัคร
        <Br
          sx={{
            d: {
              md: 'none',
            },
          }}
        />
        เข้าร่วมโปรแกรม
        <br />
        <Link
          sx={{
            fontWeight: 'normal',
            color: 'brand.400',
            _hover: {
              textDecor: 'underline!important',
            },
          }}
          href="#referral-registration"
        >
          คลิกเพื่อสมัคร
        </Link>
      </Text>
    ),
    img: '/images/Landing/referral/what-is-step-1.svg',
    ratio: 635 / 609,
  },
  {
    title: 'รับโค้ดจากเรา',
    content: (
      <Text>
        เราจะติดต่อกลับผ่านอีเมล พร้อมรายละเอียดและโค้ด สำหรับใช้เพื่อโปรโมต
      </Text>
    ),
    img: '/images/Landing/referral/what-is-step-2.svg',
    ratio: 607 / 608,
  },
  {
    title: 'แชร์โค้ด',
    content: (
      <Text>
        โปรโมตโค้ดของคุณ
        <br />
        บนช่องทางต่าง ๆ
      </Text>
    ),
    img: '/images/Landing/referral/what-is-step-3.svg',
    ratio: 604 / 653,
  },
  {
    title: 'รอรับเงินได้เลย',
    content: (
      <Text>
        ทุกยอดขายที่เกิดจากโค้ด
        <br />
        ของคุณรับไปเลย คอมมิชชัน 5%
      </Text>
    ),
    img: '/images/Landing/referral/what-is-step-4.svg',
    ratio: 623 / 688,
  },
]

export const WHY_PDPA_PRO_REFERRALS = [
  {
    icon: '/images/Landing/referral/why-referral-1.svg',
    title: 'สร้างรายได้ง่ายๆ โดยไม่ต้องลงทุน',
    content:
      'เพียงแค่แชร์โค้ดเท่านั้น คุณก็รอรับเงินได้ทันที ไม่มีค่าสมัคร และไม่ต้องมีเงินลงทุนเพิ่มเติม',
    ratio: 56 / 52,
  },
  {
    icon: '/images/Landing/referral/why-referral-2.svg',
    title: 'โปรโมตได้หลากหลายรูปแบบ',
    content:
      'แชร์โค้ดใน Social media ในบทความ Website ของคุณ หรือใน Comment บนโพสต์ต่าง ๆ ก็ได้',
    ratio: 49 / 48,
  },
  {
    icon: '/images/Landing/referral/why-referral-3.svg',
    title: 'แชร์ได้ไม่จำกัด',
    content:
      'ยิ่งแชร์มาก ยิ่งได้มาก เราจ่ายค่าคอมมิชชันให้คุณตามจำนวนโค้ดของคุณ ที่ถูกกรอกเข้ามา ไม่มีจำกัดรายรับหรือจำนวนการแชร์',
    ratio: 49 / 48,
  },
  {
    icon: '/images/Landing/referral/why-referral-4.svg',
    title: 'ทีมงานพร้อมซัพพอร์ต',
    content:
      'เรามี Live Chat กับทีมงานที่พร้อมให้ความช่วยเหลือคุณทั้งด้าน PDPA และการติดตั้งแบนเนอร์คุกกี้',
    ratio: 55 / 48,
  },
]

export const REFERRAL_EXAMPLES = [
  {
    icon: '/images/Landing/referral/example-1.svg',
    ratio: 96 / 96,

    content: (
      <Stack className="grid-item__contents" spacing="0">
        <Text className="grid-item__title">10 คน</Text>
        <Text className="grid-item__description">
          จำนวน Subscription
          <br />
          ที่ได้ผ่านโค้ด
        </Text>
      </Stack>
    ),
  },
  {
    icon: '/images/Landing/referral/example-2.svg',
    ratio: 116 / 96,
    content: (
      <Stack className="grid-item__contents" spacing="0">
        <Text className="grid-item__title">2,500 บาท</Text>
        <Text className="grid-item__description">
          ราคา Premium
          <br />
          Privacy Policy
        </Text>
      </Stack>
    ),
  },
  {
    icon: '/images/Landing/referral/example-3.svg',
    ratio: 104 / 96,
    content: (
      <Stack className="grid-item__contents" spacing="0">
        <Text className="grid-item__title">
          (2,500*5%)*10&nbsp;&nbsp;=&nbsp;&nbsp;
          <Text as="span" sx={{ color: 'primary.400' }}>
            1,250
          </Text>
          &nbsp;บาท
        </Text>

        <Flex
          justify="space-between"
          sx={{
            p: {
              w: {
                base: '120px',
                md: '145px',
              },
            },
          }}
        >
          <Text className="grid-item__description">
            ค่าคอมมิชชัน 5% จากยอดขาย
          </Text>
          <Text className="grid-item__description">
            จำนวนเงินที่คุณ จะได้รับ
          </Text>
        </Flex>
      </Stack>
    ),
  },
]

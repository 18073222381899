import {
  AspectRatio,
  Box,
  Button,
  Container,
  Grid,
  Heading,
} from '@chakra-ui/react'
import Image from 'next/image'
import { Element } from 'react-scroll'
import { scroller } from 'react-scroll'

import { Section } from './ReferralHero.styles'
export const ReferralHero = (
  props: ReferralSectionProps,
): React.ReactElement => {
  const { id } = props

  return (
    <Section as="section" id={id}>
      <Element name={id} />
      <Box className="decor-container">
        <Box className="decor-1" />
        <Box className="decor-2" />
      </Box>

      <AspectRatio ratio={1172 / 440} className="hero-image-container">
        <Image
          src="/images/Landing/referral/referral-hero.svg"
          layout="fill"
          objectFit="contain"
          alt="referral hero image"
        />
      </AspectRatio>

      <Container className="container">
        <Heading as="h1">
          PDPA PRO
          <br />
          &nbsp;Referral Program
        </Heading>
        <Heading as="h3">
          สร้างรายได้ง่าย ๆ กับ PDPA PRO แค่แชร์โค้ด! รับค่าตอบแทนทุกคำสั่งซื้อ
        </Heading>

        <Grid className="button-container">
          <Button
            className="register-now"
            size="xl"
            colorScheme="primary"
            onClick={() => {
              scroller.scrollTo('referral-registration', {
                duration: 200,
                smooth: true,
              })
            }}
          >
            สมัครเลย!
          </Button>
          <Button
            variant="outline"
            size="xl"
            onClick={() => {
              scroller.scrollTo('what-is-referral', {
                duration: 200,
                smooth: true,
                offset: -200,
              })
            }}
            sx={{
              _hover: {
                background: '#091420',
              },
              _active: {
                background: '#091420',
              },
            }}
          >
            ข้อมูลเพิ่มเติม
          </Button>
        </Grid>
      </Container>
    </Section>
  )
}

import { Box, chakra } from '@chakra-ui/react'

export const Section = chakra(Box, {
  baseStyle: {
    '.container': {
      w: '95%',
      pt: { base: '48px' },
      pb: { base: '56px' },
    },
    '.heading-container': {
      textAlign: 'center',
      maxW: '640px',
      mx: 'auto',
      h3: {
        fontWeight: 'normal',
        fontSize: '32px',
        br: {
          display: {
            base: 'block',
            md: 'none',
          },
        },
      },
      p: {
        fontWeight: 'light',
        fontSize: { base: '20px', md: '24px' },
        maxW: { base: '296px', md: 'unset' },
        span: {
          color: 'secondary.400',
          fontWeight: 'normal',
        },
        mx: {
          base: 'auto',
        },
      },
      br: {
        display: {
          base: 'none',
          md: 'block',
        },
      },
    },
    '.content-container': {
      mb: '72px',
      '.content-item': {
        '> *:first-of-type': {
          boxSize: {
            md: '452px',
          },
        },
        justifyContent: {
          'lg+': 'space-between',
        },
        alignItems: {
          md: 'center',
        },
        '&__contents': {
          textAlign: {
            base: 'center',
            'lg+': 'start',
          },
          justifyContent: {
            'lg+': 'center',
          },
          h4: {
            fontSize: {
              base: '26px',
              md: '32px',
            },
            fontWeight: 'normal',
          },
          p: {
            fontWeight: 'light',
            fontSize: {
              base: '20px',
              md: '24px',
            },
            maxW: {
              md: '420px',
            },
          },
        },
      },
    },
    '.step-container': {
      h2: {
        fontWeight: 'normal',
        textAlign: 'center',
        fontSize: {
          base: '32px',
          md: '40px',
        },
        mb: {
          base: '68px',
          md: '48px',
        },
        br: {
          d: {
            base: 'block',
            md: 'none',
          },
        },
      },
      '&__grid': {
        justifyContent: 'space-around',
        gridTemplateColumns: {
          'lg+': 'repeat(4, 260px)',
        },
        gap: '56px',
        '&-item': {
          textAlign: 'center',
          h4: {
            lineHeight: 1.6,
            fontWeight: 'normal',
            fontSize: '32px',
          },
          p: {
            fontWeight: 'light',
            fontSize: '20px',
            mx: 'auto',
            maxW: {
              md: '272px',
              'lg+': 'none',
            },
          },
          '.grid-item__image': {
            p: {
              base: 0,
              'lg+': '32px',
            },
            pb: {
              'lg+': 0,
            },
            mb: '16px',
            '> *': {
              mx: 'auto',
            },
          },
        },
      },
    },
  },
})

import React from 'react'
import { Grid } from '@chakra-ui/react'

import { Link } from '@/lib/Link'
import { mq } from '@/lib/responsive'
import { logEvent } from '@/lib/stats/gtm'
import {
  Container,
  H1,
  P,
  Button,
  Colors,
  Background,
} from '@/components/Landing/shared'

const Hero = () => {
  return (
    <section
      css={{
        position: 'relative',
        paddingTop: '124px',
        paddingBottom: '180px',

        [mq('sm')]: {
          padding: '164px 0 286px',
        },

        [mq('lg')]: {
          padding: '234px 0 300px',
        },
      }}
      id="Hero"
    >
      <Background
        src="/images/Landing/banner-bg.jpg"
        objectPosition="bottom"
        priority={true}
      />
      <Container>
        <div
          css={{
            [mq('sm')]: {
              textAlign: 'center',
            },
            [mq('lg')]: {
              display: 'grid',
              gridTemplateColumns: '922px',
              justifyContent: 'center',
            },
          }}
        >
          <H1
            css={{
              color: Colors.WHITE,
              textAlign: 'center',
              marginBottom: '18px',
              lineHeight: '50px',
              fontSize: '32px',

              [mq('sm')]: {
                fontSize: '48px',
              },

              [mq('lg')]: {
                fontSize: '56px',
                marginBottom: '8px',
                lineHeight: '74px',
              },

              '.default': {
                display: 'none',

                [mq('sm')]: {
                  display: 'block',
                },
              },

              '.mobile-only': {
                [mq('sm')]: {
                  display: 'none',
                },
              },
            }}
          >
            สร้าง Privacy Policy ง่ายๆ&nbsp;
            <br className="default" />
            อย่างมืออาชีพ&nbsp;
            <br className="mobile-only" />
            ถูกต้องตาม PDPA
          </H1>

          <P
            css={{
              color: Colors.WHITE,
              textAlign: 'center',
              marginBottom: '14px',

              '.default': {
                display: 'none',

                [mq('sm')]: {
                  display: 'block',
                },
              },

              [mq('sm')]: {
                maxWidth: '558px',
                margin: '0 auto',
                marginBottom: '24px',
              },

              [mq('lg')]: {
                maxWidth: 'unset',
                marginBottom: '32px',
              },
            }}
          >
            <strong>PDPA PRO</strong> รับทำ Privacy Policy ให้คุณในไม่กี่นาที{' '}
            <br className="default" />
            ใช้งานได้จริง หายห่วงเรื่องกฎหมาย
          </P>

          <Grid
            justifyContent="center"
            gridAutoFlow="row"
            gap="24px"
            css={{ [mq('sm')]: { gridAutoFlow: 'column' } }}
          >
            <Link route="selectPolicy" passHref>
              <Button
                onClick={() => {
                  logEvent({
                    ga: {
                      category: 'Landing Page',
                      action: 'Click create policy',
                    },
                  })
                  logEvent({
                    ga: {
                      category: 'Landing Page',
                      action: 'Click create policy header',
                    },
                  })
                }}
              >
                สร้าง Policy
              </Button>
            </Link>

            <a href="#CheckWebsite">
              <Button
                variant="outline"
                colorScheme="white"
                sx={{
                  backgroundColor: 'transparent',
                }}
                onClick={() => {
                  logEvent({
                    ga: {
                      category: 'Landing Page',
                      action: 'Click check my website button',
                    },
                  })
                }}
              >
                ตรวจสอบเว็บไซต์ตาม PDPA
              </Button>
            </a>
          </Grid>
        </div>
      </Container>
    </section>
  )
}

export default Hero

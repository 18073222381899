import { Box, Grid, Image, Text } from '@chakra-ui/react'

import { Container } from '@/components/Landing/shared'

const SiteUpdate = (): React.ReactElement => {
  return (
    <Box
      as="section"
      sx={{
        py: {
          base: '48px',
          md: '120px',
        },
      }}
    >
      <Container>
        <Grid
          sx={{
            borderRadius: '4px',
            boxShadow: '4px 4px 20px rgba(135, 135, 135, 0.25)',
            p: {
              base: '16px 16px 20px',
              'lg+': '12px 32px 16px',
            },
            alignItems: 'center',
            gridTemplateColumns: {
              base: 'max-content 1fr',
              'lg+': 'repeat(2, max-content) 1fr',
            },
            gridTemplateRows: {
              base: 'repeat(2, max-content)',
              'lg+': 'unset',
            },
            pos: 'relative',
            overflow: 'hidden',
            _after: {
              content: '""',
              w: 'full',
              h: '4px',
              d: 'block',
              pos: 'absolute',
              bg: 'linear-gradient(315deg, #00E3AB 0%, #32C4D7 100%)',
              bottom: 0,
            },
          }}
        >
          <Image
            src="/images/Landing/site-update-megaphone.svg"
            sx={{
              boxSize: {
                base: '32px',
                'lg+': '40px',
              },
            }}
          />
          <Text
            sx={{
              ml: {
                base: 4,
                'lg+': 5,
              },
              fontSize: {
                base: '18px',
              },
            }}
          >
            อัปเดตใหม่!
          </Text>
          <Text
            sx={{
              color: 'customGray.700',
              fontWeight: 300,
              gridColumn: {
                base: '1/-1',
                'lg+': 'unset',
              },
              mt: {
                base: 3,
                'lg+': 0,
              },
              ml: {
                'lg+': 1,
              },
              fontSize: {
                'lg+': '18px',
              },
            }}
          >
            ข้ามขั้นตอนหรือแก้ไขนโยบายเฉพาะส่วน, เสริมเงื่อนไขความปลอดภัย
          </Text>
        </Grid>
      </Container>
    </Box>
  )
}

export default SiteUpdate

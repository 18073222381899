import React from 'react'
import { Select, Flex, Icon } from '@chakra-ui/react'
import { CgChevronDown } from 'react-icons/cg'

import { Default, Mobile } from '@/lib/responsive'

const ServiceSelection = (props) => {
  const { services, selectedIndex, setSelectedIndex } = props

  const service = services[selectedIndex]

  return (
    <Flex
      borderRadius="36px"
      p="8px"
      justifyContent="space-between"
      sx={{
        gridTemplateColumns: '1fr',
        gap: 2,
        flexFlow: 'row wrap',
        justifyContent: 'center',
        border: { base: 'solid 1px #e8e8e8', sm: 'none' },
        width: '100%',

        '& > *': {
          width: 'fit-content',
        },
      }}
    >
      <Default>
        {services.map(({ shortenTitle, title, color }, nItemInRow) => {
          const isSelected = selectedIndex === nItemInRow

          return (
            <Flex
              key={`services-select-${nItemInRow}`}
              bg={isSelected ? color : '#ffffff'}
              fontWeight={isSelected ? 500 : 300}
              color={isSelected ? '#ffffff' : '#2b2b2b'}
              borderRadius="full"
              border="1px solid #e8e8e8"
              px="16px"
              py="10px"
              whiteSpace="nowrap"
              h="50px"
              m="4px"
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              fontSize="sm"
              css={{ transition: 'backgroundColor 0.3' }}
              onClick={() => setSelectedIndex(nItemInRow)}
            >
              {shortenTitle || title}
            </Flex>
          )
        })}
      </Default>
      <Mobile>
        <Select
          onChange={(e) => setSelectedIndex(e.target.value)}
          bg={service.color}
          fontWeight="500"
          color="#ffffff"
          borderRadius="36px"
          px="24px"
          py="12px"
          h="50px"
          border="none"
          fontSize="sm"
          icon={<Icon as={CgChevronDown} sx={{ mr: 16 }} />}
        >
          {services.map(({ title }, index) => (
            <option value={index} key={`option-${index}`}>
              {title}
            </option>
          ))}
        </Select>
      </Mobile>
    </Flex>
  )
}

export default ServiceSelection

import React, { Fragment } from 'react'
import { Text, List, ListItem, OrderedList } from '@chakra-ui/react'

export const questionGroups = [
  {
    title: 'คำถามที่พบบ่อย',
    questions: [
      {
        question: 'PDPA Pro คืออะไร',
        answer: (
          <Fragment>
            <Text fontWeight="300">
              PDPA Pro คือผู้ให้บริการสร้าง Privacy Policy รวมถึง Policy
              อื่นๆที่เกี่ยวข้องกับการเก็บข้อมูลส่วนบุคคลผ่านทางเว็บไซต์
              โดยผู้ใช้งานเพียงตอบคำถามต่างๆเกี่ยวกับข้อมูลของบริษัท
              วิธีการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลของผู้ใช้งาน
              และผู้รับผิดชอบที่เกี่ยวข้อง
            </Text>
            <br />
            <Text fontWeight="500">
              จากนั้น PDPA Pro จะนำข้อมูลที่ได้มาประมวลผลเป็น Policy
              ที่พร้อมใช้งาน ทั้งในรูปแบบไฟล์สำหรับดาวน์โหลด
              และลิงก์สาธารณะที่โฮสต์โดย PDPA Pro
            </Text>
          </Fragment>
        ),
      },
      {
        question: 'PDPA Pro จ่ายครั้งเดียวจบหรือเป็น Subscription',
        answer: (
          <Fragment>
            <Text fontWeight="300">
              จ่ายครั้งเดียวจบ Policy ของเราเป็นการขายแบบ one time purchase
              ไม่ต้องจ่ายเป็นรายเดือน คุณก็ได้ Policy ไปใช้งานได้ตลอดไป
              เข้าถึงได้ทุกเมื่อผ่านแดชบอร์ด
              พร้อมอัปเดตให้เมื่อกฎหมายมีการเปลี่ยนแปลง
            </Text>
          </Fragment>
        ),
      },
      {
        question: 'ใครบ้างที่ต้องทำ Privacy Policy',
        answer: (
          <Fragment>
            <Text fontWeight="300">
              ทุกธุรกิจหรือเว็บไซต์ที่ที่มีการเก็บรวมรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งาน
            </Text>
            <Text fontWeight="500">ตัวอย่างธุรกิจที่ต้องมี Privacy Policy</Text>
            <List fontWeight="300" styleType="disc">
              <ListItem>
                ธุรกิจใดก็ตามที่เก็บข้อมูลลูกค้าหรือผู้ใช้งานไม่ว่าจะเป็น ชื่อ
                อีเมล์ หรือเบอร์โทรศัพท์เพื่อเสนอสินค้า บริการ หรือทำการตลาด
              </ListItem>
              <ListItem>
                เว็บไซต์ที่เก็บข้อมูลการล็อกอินด้วยอีเมลหรือบัญชี Social Network
              </ListItem>
              <ListItem>ธุรกิจขายของออนไลน์ที่เก็บข้อมูลการชำระเงิน</ListItem>
            </List>
          </Fragment>
        ),
      },
      {
        question: 'เมื่อชำระเงินแล้ว สามารถรับ Policy ได้อย่างไร',
        answer: (
          <Fragment>
            <Text fontWeight="500">หากคุณชำระเงินด้วยบัตรเครดิต:</Text>
            <Text fontWeight="300">
              คุณจะได้รับ Policy ทันทีผ่านหน้าเว็บไซต์เมื่อชำระเงินสำเร็จ
            </Text>
            <Text fontWeight="500">หากคุณชำระเงินด้วยการโอน:</Text>
            <Text fontWeight="300">
              หลังจากที่คุณชำระเงินและอัปโหลดหลักฐานการโอนเงินแล้ว
              เราจะทำการยืนยันหลักฐานการโอนและทำการ Approve Policy ภายใน 2
              วันทำการ จากนั้นเราจะแจ้งผลการ Approve Policy
              ผ่านทางอีเมลพร้อมกับวิธีการรับ Policy หรือคุณสามารถรับ Policy
              ได้ด้วยตนเองผ่านทางแดชบอร์ดของ PDPA Pro
            </Text>
          </Fragment>
        ),
      },
      {
        question: 'Policy ที่ได้รับเป็นรูปแบบใดบ้าง',
        answer: (
          <Fragment>
            <Text fontWeight="300">
              คุณจะได้รับ Policy ทั้งเวอร์ชันภาษาไทย และภาษาอังกฤษ
              ในหลากหลายรูปแบบ ผ่านการจัดทำเพียงครั้งเดียว ดังนี้:
            </Text>
            <OrderedList fontWeight="300">
              <ListItem>
                Public URL
                ที่สามารถคัดลอกไปติดตั้งบนเว็บไซต์ได้อย่างสะดวกโดยไม่ต้องสร้างหน้า
                Policy เอง
              </ListItem>
              <ListItem>Plain Text (.txt)</ListItem>
              <ListItem>
                HTML Code เหมาะกับการใช้บนเว็บไซต์กรณีที่ต้องการสร้างหน้า Policy
                ด้วยตนเอง
              </ListItem>
              <ListItem>PDF สำหรับดาวน์โหลด</ListItem>
              <ListItem>Word File (.docx) สำหรับดาวน์โหลด</ListItem>
            </OrderedList>
          </Fragment>
        ),
      },
    ],
  },
]

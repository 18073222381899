import { Box, chakra } from '@chakra-ui/react'

import { DECOR_1_Z_INDEX } from '../../constants'

const bgImage = {
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
}

export const Section = chakra(Box, {
  baseStyle: {
    color: 'white',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    backgroundSize: { base: '2800px', xxl: '140vw', '3xl': '150vw' },
    backgroundImage: 'url(/images/Landing/referral/referral-hero-bg.svg)',
    backgroundPosition: {
      base: 'bottom -220px center',
      md: 'bottom -143px center',
      'lg+': 'bottom -195px left -725px',
      xl: 'bottom -174px left -300px',
      '3xl': 'bottom -285px center',
      '4xl': 'bottom -18.5vh center',
    },
    h1: {
      lineHeight: 1.5,
      textAlign: 'center',
      fontSize: { base: '32px', md: '60px' },
      mt: {
        base: '72px',
        md: '100px',
        lg: '64px',
      },
      br: {
        d: {
          base: 'block',
          lg: 'none',
        },
      },
    },
    h3: {
      fontWeight: 'normal',
      fontSize: { base: '20px', md: '32px' },
      lineHeight: 1.5,
      maxW: { base: '247px', md: '515px' },
      textAlign: 'center',
      mx: 'auto',
      mb: '32px',
    },
    '.hero-image-container': {
      transform: {
        base: 'scale(1.5)',
        lg: 'none',
      },
      maxW: { 'lg+': '1172px' },
      mx: 'auto',
      mt: {
        base: '144px',
        md: '180px',
        lg: '120px',
      },
    },
    '.button-container': {
      mx: 'auto',
      w: { base: '', 'lg+': '452px' },
      mt: { base: '', 'lg+': '32px' },
      gap: { base: '24px', 'lg+': '32px' },
      gridTemplateColumns: {
        base: 'unset',
        md: '262px 167px',
      },
      justifyContent: {
        md: 'center',
      },
      button: {
        fontSize: '24px',
        h: '72px',
      },
    },
    '.container': {
      px: {
        base: '32px',
        'lg+': 0,
      },
      pb: {
        base: '180px',
        'lg+': '200px',
        xxl: '240px',
      },
    },
    '.decor-container': {
      '> *': {
        pos: 'absolute',
        d: {
          base: 'none',
          md: 'block',
        },
      },
      '.decor-1': {
        ...bgImage,
        zIndex: DECOR_1_Z_INDEX,
        backgroundImage: 'url(/images/Landing/referral/decor-1.svg)',
        w: {
          md: '826px',
        },
        h: {
          md: '770px',
        },
        left: {
          md: '-589px',
          'lg+': '-463px',
          xl: '-366px',
        },
        top: {
          md: '-160px',
          'lg+': '-162px',
          xl: '-177px',
        },
      },
      '.decor-2': {
        ...bgImage,
        backgroundImage: 'url(/images/Landing/referral/decor-2.svg)',
        w: {
          md: '459px',
        },
        h: {
          md: '504px',
        },
        right: {
          md: '-390px',
          'lg+': '-160px',
          xl: '-110px',
        },
        bottom: {
          md: '100px',
          'lg+': '45px',
        },
      },
    },
  },
})

import {
  AspectRatio,
  Container,
  Grid,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'
import Image from 'next/image'

import { WHY_PDPA_PRO_REFERRALS } from '../../constants/referral'
import { useInViewAnimation } from '../../utils'

import { MotionFlex } from '@/components/animations'
import { Section } from './WhyReferral.styles'

export const WhyReferral = (
  props: ReferralSectionProps,
): React.ReactElement => {
  const { id } = props

  const [ref, controls] = useInViewAnimation()

  return (
    <Section as="section" id={id} ref={ref}>
      <Container className="container">
        <Heading as="h2">ทำไมต้อง PDPA Pro Referral Program</Heading>

        <Grid className="whyus-grid">
          {WHY_PDPA_PRO_REFERRALS.map((r, idx) => {
            return (
              <MotionFlex
                className="whyus-grid__grid-item"
                key={idx}
                custom={idx}
                initial={{ y: 30, opacity: 0 }}
                animate={controls}
              >
                <AspectRatio ratio={r.ratio} className="grid-item__image">
                  <Image src={r.icon} layout="fill" objectFit="contain" />
                </AspectRatio>

                <Stack spacing="0" className="grid-item__contents">
                  <Text className="grid-item__title">{r.title}</Text>
                  <Text className="grid-item__content">{r.content}</Text>
                </Stack>
              </MotionFlex>
            )
          })}
        </Grid>
      </Container>
    </Section>
  )
}

import React, { Fragment } from 'react'
import {
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Stack,
  VStack,
  Link,
} from '@chakra-ui/react'

import { Container } from '@/components/Landing/shared'

import { questionGroups } from './constant'

function FAQ(): React.ReactElement {
  return (
    <Fragment>
      <div css={{ overflow: 'hidden' }}>
        <Container
          css={{ display: 'grid', padding: '32px 0', fontWeight: 300, gap: 32 }}
        >
          {questionGroups.map(({ title, questions }, groupIndex) => (
            <Box key={`group-${groupIndex}`}>
              <Heading
                as="h1"
                fontWeight="500"
                css={{
                  margin: '18px 0',
                  textAlign: 'center',
                }}
              >
                {title}
              </Heading>
              <Accordion allowToggle defaultIndex={[]}>
                {questions.map(({ question, answer }, questionIndex) => (
                  <AccordionItem
                    key={`question-${groupIndex}-${questionIndex}`}
                  >
                    <AccordionButton
                      fontWeight="300"
                      minH="64px"
                      _expanded={{ bg: '#e8e8e8', fontWeight: 500 }}
                    >
                      <Box flex="1" textAlign="left">
                        {question}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>{answer}</AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          ))}
          <VStack>
            <Stack
              direction={{
                base: 'column',
                lg: 'row',
              }}
            >
              <Link
                isExternal
                href="faq"
                sx={{ fontWeight: 'medium', textAlign: 'center' }}
              >
                อ่านคำถามที่พบบ่อยทั้งหมด
              </Link>
            </Stack>
          </VStack>
        </Container>
      </div>
    </Fragment>
  )
}

export default FAQ

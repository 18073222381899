import React from 'react'
import { createIcon } from '@chakra-ui/icons'

export const FacebookIcon = createIcon({
  displayName: 'Facebook',
  path: (
    <path
      fill="currentColor"
      d="M6.965 7.676v-2.46c0-.935.564-1.132.988-1.132h2.541V0H7.012c-3.86 0-4.753 3.05-4.753 4.97v2.706H0v4.183h2.259v11.859h4.706v-11.86h3.2l.376-4.182H6.965z"
    />
  ),
  viewBox: '0 0 11 24',
})

export const LinkedinIcon = createIcon({
  displayName: 'Linkedin',
  path: (
    <path
      fill="currentColor"
      d="M6.965 7.676v-2.46c0-.935.564-1.132.988-1.132h2.541V0H7.012c-3.86 0-4.753 3.05-4.753 4.97v2.706H0v4.183h2.259v11.859h4.706v-11.86h3.2l.376-4.182H6.965z"
    />
  ),
  viewBox: '0 0 11 24',
})

export const MediumIcon = createIcon({
  displayName: 'Medium',
  path: (
    <path
      fill="currentColor"
      d="M6.965 7.676v-2.46c0-.935.564-1.132.988-1.132h2.541V0H7.012c-3.86 0-4.753 3.05-4.753 4.97v2.706H0v4.183h2.259v11.859h4.706v-11.86h3.2l.376-4.182H6.965z"
    />
  ),
  viewBox: '0 0 11 24',
})

export const HamburgerIcon = createIcon({
  displayName: 'Hamburger',
  path: (
    <path
      fill="currentColor"
      d="M6.965 7.676v-2.46c0-.935.564-1.132.988-1.132h2.541V0H7.012c-3.86 0-4.753 3.05-4.753 4.97v2.706H0v4.183h2.259v11.859h4.706v-11.86h3.2l.376-4.182H6.965z"
    />
  ),
  viewBox: '0 0 11 24',
})

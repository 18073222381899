import { useEffect, useRef, useState } from 'react'
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  HStack,
  Link as ChakraLink,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import Image from 'next/image'
import { scroller } from 'react-scroll'
import { animated, useTransition } from 'react-spring'

import { REFERRAL_NAV_Z_INDEX } from '../../constants'
import { Link } from '@/lib/Link'

const AnimatedBox = animated(Box)

export const ReferralNav = (): React.ReactElement => {
  const [navAltStyle, setNavStyle] = useState(false)
  const [logoAltStyle, setLogoStyle] = useState(false)
  const scrollTO = useRef<number>()

  useEffect(() => {
    const handleScroll = () => {
      window.clearTimeout(scrollTO.current)

      scrollTO.current = window.setTimeout(() => {
        const hero = document.querySelector('#referral-hero')
        const h1 = document.querySelector('#referral-hero h1')

        if (hero && h1) {
          const { top, height } = hero.getBoundingClientRect()
          const h1Rect = h1.getBoundingClientRect()

          setLogoStyle(Math.abs(top) + 100 > height)
          setNavStyle(h1Rect.top - 50 > h1Rect.height)
        }
      }, 25)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const transitions = useTransition(logoAltStyle, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { position: 'absolute', opacity: 0 },
    reverse: logoAltStyle,
    delay: 100,
  })

  return (
    <Flex
      sx={{
        pos: 'fixed',
        w: 'full',
        h: '72px',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: {
          base: '24px',
        },
        zIndex: REFERRAL_NAV_Z_INDEX,
        transition: 'background-color 0.2s ease-in-out',
        bg: logoAltStyle ? 'white' : navAltStyle ? 'transparent' : '#0d1d2e',
      }}
    >
      <Box
        sx={{
          w: '118px',
          d: {
            base: 'none',
            md: 'block',
          },
        }}
      ></Box>
      <HStack
        spacing={3}
        divider={
          <StackDivider
            sx={{
              borderColor: 'secondary.400',
              borderRightWidth: '1px',
              d: {
                base: 'none',
                md: 'block',
              },
            }}
          />
        }
      >
        <Link route="home">
          <ChakraLink
            sx={{
              cursor: 'pointer',
            }}
          >
            <AspectRatio
              ratio={169 / 40}
              sx={{ w: { base: '140px', md: '169px' } }}
            >
              {transitions(({ opacity }, item) =>
                item ? (
                  <AnimatedBox
                    style={{
                      opacity: opacity.to({
                        range: [0.0, 1.0],
                        output: [0, 1],
                      }),
                    }}
                  >
                    <Image
                      src="/images/logo.svg"
                      layout="fill"
                      objectFit="contain"
                      alt="pdpa pro logo"
                    />
                  </AnimatedBox>
                ) : (
                  <AnimatedBox
                    style={{
                      opacity: opacity.to({
                        range: [1.0, 0.0],
                        output: [1, 0],
                      }),
                    }}
                  >
                    <Image
                      src="/images/logo-alt.svg"
                      layout="fill"
                      objectFit="contain"
                      alt="pdpa pro logo"
                    />
                  </AnimatedBox>
                ),
              )}
            </AspectRatio>
          </ChakraLink>
        </Link>

        <Text
          sx={{
            color: 'secondary.400',
            fontSize: '28px',
            flex: 1,
            d: {
              base: 'none',
              md: 'block',
            },
          }}
        >
          Referral Program
        </Text>
      </HStack>

      <Button
        colorScheme="primary"
        size="lg"
        sx={{ w: '118px', fontSize: '16px' }}
        onClick={() => {
          scroller.scrollTo('referral-registration', {
            duration: 200,
            smooth: true,
          })
        }}
      >
        สมัครเลย!
      </Button>
    </Flex>
  )
}

import React from 'react'
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Icon,
} from '@chakra-ui/react'

import { Link as CustomLink } from '@/features/home/components'
import { Link } from '@/lib/Link'

import { NavItem } from './share.style'

const SubMenus = ({
  menu,
  altStyle,
}: {
  menu: {
    to: string
    path: string
    hash: string
    label: string
    subMenus: {
      route?: string
      path?: string
      to?: string
      hash?: string
      query?: Record<string, string>
      label: string
      image?: React.FC<React.SVGAttributes<SVGElement>>
    }[]
  }
  altStyle: boolean
}) => {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Box h="full">
          <CustomLink to={menu.to} path={menu.path} hash={menu.hash}>
            {() => (
              <NavItem className="nav-item" altStyle={altStyle}>
                {menu.label}
              </NavItem>
            )}
          </CustomLink>
        </Box>
      </PopoverTrigger>
      <PopoverContent
        sx={{
          position: 'relative',
          overflow: 'hidden',
          zIndex: 20,
          boxShadow: 'none !important',
          outline: 'none !important',
          border: 'none',
          backgroundColor: '#0000005f',
          minW: '200px',
          w: 'fit-content',
          '& > *': {
            py: 2,
            textAlign: 'left',
            alignSelf: 'flex-start',
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            w: 'full',
            h: 'full',
            backdropFilter: altStyle ? 'unset' : 'blur(16px)',
          },
        }}
      >
        {menu.subMenus.map((m, idx) => {
          let render = (children: JSX.Element) => children

          if (m.route) {
            render = (children) => {
              return (
                <Link key={idx} route={m.route} passHref>
                  {children}
                </Link>
              )
            }
          } else if (m.path) {
            render = (children) => {
              return (
                <CustomLink
                  to={menu.to}
                  path={menu.path}
                  hash={menu.hash}
                  key={idx}
                  query={m.query}
                >
                  {() => children}
                </CustomLink>
              )
            }
          } else {
            render = (children) => {
              return (
                <CustomLink to={m.to} key={idx}>
                  {() => children}
                </CustomLink>
              )
            }
          }

          return render(
            <NavItem
              key={idx}
              className="nav-item"
              altStyle={altStyle}
              css={{
                justifyContent: 'flex-start',
                backgroundColor: altStyle ? 'white' : undefined,
              }}
            >
              {m.image && (
                <Icon
                  as={m.image}
                  boxSize="24px"
                  sx={{ mr: 2 }}
                  color={'inherit'}
                />
              )}
              {m.label}
            </NavItem>,
          )
        })}
      </PopoverContent>
    </Popover>
  )
}

export default SubMenus

import { string, object, boolean } from 'yup'

export const registrationFormSchema = object().shape({
  name: string().required('กรุณาระบุชื่อของคุณ'),
  email: string().email('อีเมล์ไม่ถูกต้อง').required('กรุณาระบุอีเมล์ของท่าน'),
  tel: string()
    .required('กรุณาระบุเบอร์โทรติดต่อของคุณ')
    .matches(/^[0-9]+$/, 'กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก')
    .length(10, 'กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก'),
  company: string(),
  message: string(),
  accept: boolean(),
  newsletter: boolean(),
})

import React from 'react'
import { Box, Grid, Image } from '@chakra-ui/react'

import { mq, Mobile } from '@/lib/responsive'
import {
  Container,
  // ExternalLink,
  Background,
} from '@/components/Landing/shared'
import { links } from './constant'
import { Link } from '@/features/home/components'

const Contact = () => {
  return (
    <section
      css={{
        position: 'relative',

        [mq('sm')]: {
          padding: '60px 0 0 0',
        },
      }}
      id="Contact"
    >
      <Background
        src="/images/Landing/svg-footer.svg"
        css={{
          display: 'none',
          [mq('md+')]: {
            display: 'block',
          },
        }}
        objectPosition="bottom"
        objectFit="contain"
      />
      <Box
        h="8px"
        w="full"
        mb="60px"
        bg="linear-gradient(315deg, #00E3AB 0%, #32C4D7 100%)"
      >
        <Image
          src="/images/Landing/shield-2.svg"
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Box>
      <Container
        css={{
          position: 'relative',
          zIndex: 1,

          [mq('lg')]: {
            fontSize: '20px',
            fontWeight: 300,
          },
        }}
      >
        <Grid
          css={{
            rowGap: 41,
            gridTemplateRows: 'repeat(4, max-content)',
            justifyContent: 'center',

            [mq('md+')]: {
              columnGap: 220,
              gridTemplateColumns: 'repeat(2, max-content)',
            },
          }}
        >
          <Box
            fontSize="20px"
            fontWeight="500"
            css={{
              gridRow: '1',

              [mq('md+')]: {
                gridColumn: '1',
                gridRow: '1',
              },
            }}
          >
            <Grid
              columnGap="52px"
              rowGap="24px"
              css={{
                textAlign: 'center',

                [mq('sm')]: {
                  textAlign: 'left',
                  gridTemplateColumns: 'repeat(2, max-content)',
                },
              }}
            >
              {links.map(({ name, ...rest }, index) => (
                <Link {...rest} key={`link-${index}`}>
                  {({ onClick }) => (
                    <a onClick={onClick} css={{ color: '#2b2b2b' }}>
                      {name}
                    </a>
                  )}
                </Link>
              ))}
            </Grid>
          </Box>
          <Box
            textAlign="left"
            fontSize="19px"
            css={{
              gridRow: '3',
              textAlign: 'center',

              [mq('sm')]: {
                textAlign: 'left',
              },

              [mq('md+')]: {
                gridColumn: '2',
                gridRow: '1',
              },
            }}
          >
            <Box
              css={{
                width: '100%',
                height: '40px',
                position: 'relative',
                marginBottom: '10px',

                [mq('sm')]: {
                  marginBottom: '16px',
                },

                [mq('lg')]: {
                  marginBottom: '27px',
                },
              }}
            >
              <Background
                src="/images/Landing/logo-with-iso.svg"
                objectFit="contain"
              />
            </Box>

            <p
              css={{
                fontWeight: 300,
                width: '100%',
                fontSize: 19,

                [mq('sm')]: {
                  width: '470px',
                },

                [mq('lg')]: { marginBottom: '20px' },
              }}
            >
              บริษัท เอ็นเดต้าธอธ จำกัด
              <br />
              1778 อาคารซัมเมอร์ฮับ ออฟฟิศ, ชั้น 6{' '}
              <Mobile>
                <br />
              </Mobile>
              ถนนสุขุมวิท แขวงพระโขนง เขตคลองเตย กรุงเทพมหานคร 10110 ประเทศไทย
            </p>

            <p
              css={{
                fontWeight: 300,
                fontSize: 19,
              }}
            >
              <a
                css={{ fontSize: 28, position: 'relative', zIndex: 1 }}
                href="tel:020245560"
              >
                โทร: 02-024-5560
              </a>
              <br />
              sales@ndatathoth.com
            </p>
          </Box>
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, max-content) 1fr',
              alignItems: 'center',
              gap: '24px',
              gridRow: '4',

              [mq('md+')]: {
                marginBottom: '50px',
                gridColumn: '2',
                gridRow: '2',
              },
            }}
          >
            {/*<ExternalLink href="https://www.facebook.com/datawowai">
              <Image
                src="/icons/icon-facebook.svg"
                alt="facebook-icon"
                width={11}
                height={24}
              />
            </ExternalLink>
            <ExternalLink href="https://www.linkedin.com/company/datawowio/">
              <Image
                src="/icons/icon-linkedin.svg"
                alt="linkedin-icon"
                width={25}
                height={24}
              />
            </ExternalLink>
            <ExternalLink href="https://blog.datawow.io">
              <Image
                src="/icons/icon-dw-blog.svg"
                alt="dw-blog-icon"
                width={25}
                height={25}
              />
            </ExternalLink>*/}
            <iframe
              src="https://maps.google.com/maps?q=NDATATHOTH%20COMPANY%20LIMITED&t=&z=17&ie=UTF8&iwloc=&output=embed"
              css={{
                gridColumn: '1 / -1',
                width: '100%',
                height: 216,
              }}
              title="ndatathoth-map"
            ></iframe>
          </div>
        </Grid>
      </Container>
    </section>
  )
}

export default Contact

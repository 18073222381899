import { Box, Text, VStack, SimpleGrid } from '@chakra-ui/react'
import Image from 'next/image'

import { Container } from '@/components/Landing/shared'

const IMAGES = [
  {
    src: '/images/Landing/certified/iso-logo.svg',
    alt: 'iso',
  },
]

function Certified(): React.ReactElement {
  return (
    <Box
      as="section"
      sx={{
        pt: {
          base: '60px',
          md: '120px',
        },
        pb: { md: 10 },
      }}
    >
      <Container>
        <VStack spacing="10">
          <Text
            as="h2"
            sx={{
              fontSize: { base: '32px', md: '40px' },
              fontWeight: 'medium',
            }}
          >
            Certified
          </Text>
          <SimpleGrid columns={1} gap="8">
            {IMAGES.map((image, index) => {
              return (
                <Box
                  key={`${index}`}
                  as="figure"
                  sx={{
                    w: { base: '209px', md: '230px' },
                    h: { base: '80px', md: '88px' },
                  }}
                >
                  <Image
                    src={image.src}
                    alt={image.alt}
                    width="230px"
                    height="88px"
                    objectFit="contain"
                    layout="responsive"
                  />
                </Box>
              )
            })}
          </SimpleGrid>
          <Text
            sx={{
              textAlign: 'center',
              fontWeight: 'light',
            }}
          >
            PDPA Pro ได้รับ มาตรฐาน{' '}
            <Text
              as="strong"
              sx={{ fontWeight: 'medium', color: 'primary.400' }}
            >
              ISO&nbsp;27001 (Information&nbsp;Security)
            </Text>{' '}
            <Box as="br" sx={{ display: { base: 'none', md: 'block' } }} />
            และมาตรฐาน{' '}
            <Text
              as="strong"
              sx={{ fontWeight: 'medium', color: 'primary.400' }}
            >
              ISO&nbsp;27701 (Privacy&nbsp;Information)
            </Text>
          </Text>
        </VStack>
      </Container>
    </Box>
  )
}

export default Certified

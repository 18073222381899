import React from 'react'
import Link from 'next/link'
import { Box, Flex } from '@chakra-ui/react'
import Image from 'next/image'

import { css } from '@emotion/react'
import { formatDateWithTime } from '@/lib/helpers'
import { Tags } from '@/features/blogs/types'

import { Tag } from '@/features/blogs/components/Tag'

type BlogItemProps = {
  image: string
  title: string
  link: string
  date: string
  tags: Tags[]
}

const BlogItem = (props: BlogItemProps) => {
  const { image, title, link, date, tags } = props

  return (
    <Box>
      <Link href={link} passHref>
        <a css={{ color: '#000' }}>
          <div
            css={{
              display: 'flex',
              textAlign: 'start',
              cursor: 'pointer',
              flexFlow: 'column nowrap',
              justifyContent: 'flex-start',
            }}
          >
            <figure
              css={css`
                & {
                  position: relative;
                  padding: 52.27% 0 0 0;
                  width: 100%;
                  margin-bottom: 16px;
                }
                & > * {
                  position: absolute !important;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                }
              `}
            >
              <Image
                title={title}
                src={image}
                alt={title}
                objectFit="cover"
                width={1920}
                height={1008}
              />
            </figure>
            <p css={{ marginBottom: '16px' }}>{title}</p>
            <p>{formatDateWithTime(date, 'DD/MM/YY')}</p>
          </div>
        </a>
      </Link>
      <Flex gap={2} sx={{ mt: 2, flexWrap: 'wrap' }}>
        {tags.map((data, idx) => (
          <Tag key={idx} name={data.name} tagId={data.id} />
        ))}
      </Flex>
    </Box>
  )
}

export default BlogItem

import React from 'react'
import { Container, Flex, Grid, Text, VStack } from '@chakra-ui/react'

import Product from './Product'
import { PRODUCTS } from './constants'
import { ReferralBanner } from '@/features/landingPage/referral'

function OtherProduct() {
  return (
    <Container
      as="section"
      id="OtherProducts"
      sx={{
        pos: 'relative',
        minW: '100%',
        pt: {
          base: '64px',
          md: '100px',
        },
      }}
    >
      <VStack spacing="8" sx={{ alignItems: 'stretch' }}>
        <Text
          as="h1"
          sx={{
            fontWeight: 'medium',
            fontSize: { base: '32px', md: '40px' },
            textAlign: 'center',
          }}
        >
          บริการอื่น ๆ ของเรา
        </Text>
        <Grid
          sx={{
            alignItems: 'center',
            alignContent: 'flex-start',
            justifyContent: 'center',
            gridGap: '24px',
            alignSelf: 'center',
            gridTemplateColumns: {
              base: 'repeat(1, 1fr)',
              'lg+': 'repeat(4, 1fr)',
            },
            w: '1104px',
            maxW: 'full',
          }}
        >
          {PRODUCTS.map((product, idx) => {
            const isScrap =
              PRODUCTS.length % 2 !== 0 && PRODUCTS.length === idx + 1

            return <Product {...product} key={product.href} isScrap={isScrap} />
          })}
        </Grid>
      </VStack>

      <Flex
        sx={{
          justifyContent: 'center',
          mt: {
            base: '64px',
            md: '108px',
          },
        }}
      >
        <ReferralBanner />
      </Flex>
    </Container>
  )
}

export default OtherProduct

import { useState } from 'react'
import { useFormik } from 'formik'
import { useToast } from '@chakra-ui/react'
import axios from 'axios'

import { fetchAPI } from '@/lib/api'
import { logEvent } from '@/lib/stats/gtm'
import { acceptConsent } from '@/lib/utils/consent'
import env from '@/config/env'
import { contactFormSchema } from './schema'

export function useContactUsForm() {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      company: '',
      position: '',
      email: '',
      tel: '',
      message: '',
      terms_and_policy_consent: false,
      marketing_consent: false,
    },
    validationSchema: contactFormSchema,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)

      const data = {
        to:
          env.MAIL_TO &&
          env.MAIL_TO.split(/,/).filter((value: string) => value),
        subject: '[PDPA Pro] A Customer wants to get in touch!',
        message:
          `First name: ${values.first_name}\n` +
          `Last name: ${values.last_name}\n` +
          `Company: ${values.company}\n` +
          `Position: ${values.position || '-'}\n` +
          `Email: ${values.email}\n` +
          `Tel: ${values.tel}\n` +
          `Message: ${values.message || '-'}\n`,
      }

      try {
        await Promise.all([
          axios.post(env.EMAIL_API_URL as string, data),
          axios.post(env.SLACK_API_URL as string, {
            webhook_url: env.SLACK_WEBHOOK_URL,
            text: `${data.subject}\n${data.message}`,
          }),

          fetchAPI({
            method: 'POST',
            path: '/contact_us_forms',
            headers: {
              Authorization: env.APP_KEY,
            },
            data: {
              name: values.first_name,
              surname: values.last_name,
              company_name: values.company,
              position: values.position,
              email: values.email,
              phone_number: values.tel,
              description: values.message,
              terms_and_policy_consent: values.terms_and_policy_consent,
              marketing_consent: values.marketing_consent,
            },
          }),

          values.marketing_consent
            ? acceptConsent({
              apiKey: env.CONSENT_WOW_CONTACT_API_KEY,
              purposeId: env.CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID,
              data: {
                ...values,
                name: values.first_name,
                surname: values.last_name,
                phone_number: values.tel,
              },
            })
            : Promise.resolve(null),
        ])

        toast({
          title: 'ขอบคุณที่สนใจในผลิตภัณฑ์ของเรา เราจะติดต่อคุณกลับไปในไม่ช้า',
          status: 'success',
          position: 'top-right',
        })
        logEvent({
          ga: {
            category: 'Landing Page',
            action: 'Check website submit success',
          },
          fb: {
            event: 'Lead',
          },
        })

        resetForm()
        setLoading(false)
      } catch {
        toast({
          title: 'มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่ในภายหลัง',
          status: 'error',
          position: 'top-right',
        })
        setLoading(false)
      }
    },
  })

  return {
    formik,
    isLoading,
  }
}

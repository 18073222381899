import React from 'react'
import PropType from 'prop-types'
import { AspectRatio, Link, Image, Box } from '@chakra-ui/react'
import { logEvent } from '@/lib/stats/gtm'

function Products({ bgColor, image, href, action, isScrap }) {
  return (
    <Box
      sx={{
        gridColumn: { base: 'span 1', 'lg+': isScrap ? '2/span 2' : 'span 2' },
      }}
    >
      <Link
        isExternal
        href={href}
        onClick={() =>
          logEvent({
            ga: { category: 'Other Product', action },
          })
        }
        sx={{
          borderRadius: '24px',
          textDecoration: 'none !important',
          color: 'white',
          justifyContent: 'flex-start !important',
          textShadow: '0 0 8px rgba(42, 42, 42, 0.8)',
          transition: 'all 350ms',
          '&:hover': {
            boxShadow: `8px 8px ${bgColor}`,
          },
          '&:focus': {
            boxShadow: `8px 8px ${bgColor}`,
          },
        }}
      >
        <AspectRatio
          ratio={540 / 160}
          sx={{ maxW: '540px', w: 'full', mx: 'auto' }}
        >
          <Image
            src={image.src}
            alt={image.alt}
            sx={{ w: 'full', h: 'full' }}
          />
        </AspectRatio>
      </Link>
    </Box>
  )
}

Products.propTypes = {
  bgColor: PropType.string.isRequired,
  href: PropType.string.isRequired,
  action: PropType.string,
}

export default Products

import React from 'react'
import Image from 'next/image'
import { Grid, Box } from '@chakra-ui/react'
import { mq, Mobile, Default } from '@/lib/responsive'

import { Container, H2, Background } from '@/components/Landing/shared'

const items = [
  {
    image: '/images/Landing/sc-7-img-testimonial-skillsolved',
    imageFormat: 'jpg',
    name: 'เทียนธันย์ ณีศะนันท์',
    position: 'CEO',
    company: 'SkillSolved Headhunter Thailand',
    message:
      'PDPA Pro ช่วยประหยัดเวลาได้มากครับ ในฐานะเจ้าของธุรกิจก็มีหลายอย่างที่ต้องทำ แต่พอได้ใช้ PDPA Pro ก็ไม่มีเรื่องต้องกังวลอีกต่อไป มั่นใจได้ว่าทำตามระบบ PDPA และยังมีทีม Customer Support ที่ให้ความช่วยเหลือดีมาก รู้สึกมั่นใจที่ได้รับการดูแลจาก Data Expert ครับ',
  },
]

const infos = [
  {
    image: '/images/Landing/icons-8-scales.svg',
    content:
      'Policy ร่างโดยทนายที่ผ่านการอบรมหลักสูตรเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (DPO)',
  },
  {
    image: '/images/Landing/icons-8-protect.svg',
    content:
      'แพลตฟอร์มจัดทำโดยผู้ให้บริการที่ได้รับมาตรฐานสากลสำหรับระบบการจัดการความปลอดภัยของข้อมูล ISO27001',
  },
]

const Testimonial = () => {
  const item = items[0]

  return (
    <section
      css={{
        padding: '104px 0',
        position: 'relative',
        color: '#e6e6e6',
        fontWeight: 300,
      }}
      id="Testimonial"
    >
      <Background src="/images/Landing/sc-7-bg.jpg" css={{ zIndex: -1 }} />
      <Container css={{ display: 'grid', rowGap: 39 }}>
        <H2 css={{ fontWeight: 500, color: '#ffffff' }}>
          <span css={{ color: '#ff7315' }}>
            มั่นใจได้เมื่อใช้บริการกับ
            <Mobile>
              <br />
            </Mobile>
          </span>
          <Default> </Default>
          PDPA Pro
        </H2>
        <Grid
          css={{
            gap: 24,

            [mq('sm')]: {
              justifyContent: 'center',
            },
            [mq('md+')]: {
              gridAutoFlow: 'column',
            },
          }}
        >
          <Grid
            css={{
              gap: 24,

              [mq('sm')]: {
                gridTemplateColumns: '1fr 1fr',
                fontSize: 20,
                padding: '0 10px',
              },
              [mq('md+')]: {
                gridTemplateColumns: 'unset',
                fontSize: 24,
                padding: '0 30px',
              },
            }}
          >
            {infos.map(({ image, content }, index) => (
              <Grid
                key={index}
                css={{
                  gap: 12,

                  [mq('sm')]: {
                    gap: 24,
                  },

                  [mq('md+')]: {
                    gridAutoFlow: 'column',
                  },
                }}
              >
                <Box
                  pos="relative"
                  boxSize="48px"
                  css={{
                    margin: '0 auto',

                    [mq('sm')]: {
                      margin: 0,
                    },
                  }}
                >
                  <Background src={image} objectFit="contain" />
                </Box>
                <Box
                  css={{
                    textAlign: 'center',
                    fontSize: 20,

                    [mq('sm')]: {
                      textAlign: 'left',
                      width: 332,
                    },
                    [mq('md+')]: {
                      fontSize: 24,
                      width: 452,
                    },
                  }}
                >
                  {content}
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid
            p="30px"
            rounded="20px"
            bg="rgba(27, 41, 56, 0.56)"
            fontSize="20px"
            css={{
              backgroundBlendMode: 'multiply',
              rowGap: 16,

              [mq('sm')]: {
                rowGap: 8,
                width: 719,
                minHeight: 326,
              },

              [mq('md+')]: {
                width: 547,
              },
            }}
          >
            <Grid
              columnGap="16px"
              css={{
                gridTemplateRows: 'repeat(2, max-content)',
                gap: 11,

                [mq('sm')]: {
                  gridTemplateRows: 'unset',
                  gridTemplateColumns: 'max-content 1fr',
                },
              }}
            >
              <Box
                sx={{
                  w: '90px',
                  h: '90px',
                  mx: 'auto',
                  my: { base: 2, md: 0 },
                }}
              >
                <Image
                  src={`${item.image}.${item.imageFormat}`}
                  width={90}
                  height={90}
                  objectFit="contain"
                  css={{
                    margin: '0 auto',
                    objectFit: 'cover',
                    border: '1px solid #ffffff',
                    borderRadius: '50%',

                    [mq('sm')]: {
                      margin: 0,
                    },
                  }}
                  alt={item.name}
                />
              </Box>
              <Box
                fontSize="20px"
                fontWeight="500"
                color="#ffffff"
                css={{
                  textAlign: 'center',

                  [mq('sm')]: {
                    textAlign: 'left',
                  },
                }}
              >
                <Box>{item.name}</Box>
                <Box>{item.position}</Box>
                <Box
                  css={{
                    fontSize: '16px',
                    [mq('sm')]: {
                      fontSize: '20px',
                    },
                  }}
                >
                  {item.company}
                </Box>
              </Box>
            </Grid>
            <Box>{`"${item.message}"`}</Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default Testimonial

export const services = [
  {
    active: true,
    type: 'privacy',
    moreInfoRoute: 'servicePrivacy',
    title: 'Privacy Policy',
    content: [
      'สำหรับธุรกิจที่เก็บรวบรวมข้อมูลส่วนบุคคลของลูกค้าหรือผู้ใช้งาน หรือประมวลผลข้อมูลส่วนบุคคลของลูกค้าหรือผู้ใช้งาน เช่น ชื่อ-นามสกุล อีเมล์ หรือเบอร์โทรศัพท์',
    ],
    color: '#4467cd',
    head: 'ความครอบคลุมที่คุณจะได้รับ',
    benefit: [
      {
        title: 'PDPA ของประเทศไทย',
        desc: 'สำหรับธุรกิจที่มีลูกค้าในประเทศไทย',
      },
      {
        title: 'CCPA ของรัฐแคลิฟอร์เนีย',
        desc: 'สำหรับธุรกิจที่มีลูกค้าอยู่ในรัฐแคลิฟอร์เนีย สหรัฐอเมริกา',
      },
      {
        title: 'GDPR ของสหภาพยุโรป',
        desc: 'สำหรับธุรกิจที่มีลูกค้าอยู่ในยุโรป',
      },
      {
        title: 'การใช้งานเชิงพาณิชย์',
      },
      {
        title: 'การทำงานร่วมกับ Third Party',
      },
      {
        title: 'การใช้ข้อมูลอ่อนไหว',
        desc: 'เช่น พฤติกรรมทางเพศ ข้อมูลพันธุกรรม ข้อมูลชีวภาพ',
      },
    ],
    plans: [
      {
        name: 'free',
        cost: 'ฟรี',
        unit: 'ฉบับ',
        color: '#ff7315',
        marginSize: '0px',
        benefit: [true, false, false, false, false, false],
      },
      {
        name: 'premium',
        cost: '2,500.-',
        unit: 'ฉบับ',
        color: 'linear-gradient(290deg, #00e3ab, #32c4d7)',
        benefit: [true, true, true, true, true, true],
      },
    ],
    buttonText: 'สร้าง Policy ตอนนี้!',
  },
  {
    active: true,
    type: 'hr_privacy',
    moreInfoRoute: 'serviceHRPrivacy',
    title: 'HR Privacy Policy',
    content: [
      'สำหรับธุรกิจที่เก็บรวบรวมข้อมูลส่วนบุคคลของพนักงาน หรือประมวลผลข้อมูลส่วนบุคคลของพนักงาน',
      'เช่น ชื่อ-นามสกุล ที่อยู่ เลขบัตรประชาชนของพนักงานเพื่อใช้ยื่นภาษี หรือประกันสังคม ',
    ],
    color: '#44cdcb',
    head: 'สิ่งที่คุณจะได้รับ',
    benefit: [
      {
        title: 'HR Policy ครอบคลุม PDPA ของไทย',
      },
    ],
    plans: [
      {
        cost: '1,199.-',
        unit: 'ฉบับ',
        benefit: [true],
      },
    ],
    buttonText: 'สร้าง HR Policy ตอนนี้',
  },
  {
    active: true,
    type: 'cookie',
    moreInfoRoute: 'serviceCookie',
    title: 'Cookies Policy',
    content: [
      'สำหรับเว็บไซต์ที่ใช้คุกกี้เก็บข้อมูลส่วนบุคคลของผู้ใช้งาน เพื่อเก็บข้อมูลที่ระบุตัวตนได้ของผู้ใช้งานเช่น IP\xa0Address, ข้อมูลการล็อกอินรวมถึงคุกกี้สำหรับการวัดผลต่างๆ เช่น Google Analytics หรือ Facebook Pixel',
    ],
    color: '#ea834f',
    head: 'ความครอบคลุมที่จะได้รับ',
    benefit: [
      {
        title: 'PDPA ของประเทศไทย',
        desc: 'สำหรับเว็บไซต์ที่มีผู้ใช้งานในประเทศไทย',
      },
      {
        title: 'GDPR ของสหภาพยุโรป',
        desc: 'สำหรับเว็บไซต์ที่มีผู้ใช้งานในสหภาพยุโรป',
      },
    ],
    plans: [
      {
        cost: '699.-',
        unit: 'ฉบับ',
        benefit: [true, true],
      },
    ],
    buttonText: 'สร้าง Cookies Policy ตอนนี้',
  },
  {
    active: true,
    type: 'cctv',
    moreInfoRoute: 'serviceCCTV',
    title: 'CCTV Policy',
    content: [
      'สำหรับธุรกิจที่มีการติดตั้งกล้องโทรทัศน์วงจรปิด (CCTV) ไม่ว่าจะในที่ทำงาน ร้านค้า โรงงาน ฯลฯ',
      'ซึ่งเป็นการเก็บข้อมูลส่วนบุคคลประเภทใบหน้าของบุคคล',
    ],
    color: '#73bbff',
    head: '​สิ่งที่คุณจะได้รับ',
    benefit: [
      {
        title: 'CCTV Privacy Policy ครอบคลุม PDPA ของประเทศไทย',
      },
    ],
    plans: [
      {
        cost: '899.-',
        unit: 'ฉบับ',
        benefit: [true],
      },
    ],
    buttonText: 'สร้าง CCTV Policy ตอนนี้',
  },
  {
    active: true,
    type: 'recruitment_privacy',
    moreInfoRoute: 'serviceRecruitment',
    title: 'Recruitment Privacy Policy',
    shortenTitle: 'Recruitment Policy',
    content: [
      'สำหรับธุรกิจที่มีการรับสมัครพนักงานและมีการเก็บรวบรวมหรือประมวลผลข้อมูลส่วนบุคคลของผู้สมัครงาน เช่น ชื่อ-นามสกุล ไปจนถึงประวัติอาชญากรรม',
    ],
    color: '#7977FC',
    head: 'สิ่งที่คุณจะได้รับ',
    benefit: [
      {
        title: 'Recruitment Privacy Policy ครอบคลุม PDPA ของไทย',
      },
    ],
    plans: [
      {
        cost: '1,299.-',
        unit: 'ฉบับ',
        benefit: [true],
      },
    ],
    buttonText: 'สร้าง Policy ตอนนี้!',
    aboveButtonText: 'ใน 1 ฉบับ คุณจะได้รับ Policy ทั้งแบบภาษาไทยและภาษาอังกฤษ',
  },
  {
    active: true,
    type: 'vendor',
    moreInfoRoute: 'serviceVendor',
    title: 'Vendor Privacy Policy',
    shortenTitle: 'Vendor Policy',
    content: [
      'สำหรับองค์กรที่มีการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคู่ค้าหรือผู้ให้บริการ เมื่อองค์กรมีการทำสัญญาหรือธุรกรรมร่วมกับคู่ค้า หรือเมื่อองค์กรได้มีการใช้บริการจากผู้ให้บริการ',
    ],
    color: '#2D89F7',
    head: 'สิ่งที่คุณจะได้รับ',
    benefit: [
      {
        title: 'Vendor Privacy Policy ครอบคลุม PDPA ของไทย',
      },
    ],
    plans: [
      {
        cost: '1,299.-',
        unit: 'ฉบับ',
        benefit: [true],
      },
    ],
    buttonText: 'สร้าง Policy ตอนนี้',
    aboveButtonText: 'ใน 1 ฉบับ คุณจะได้รับ Policy ทั้งแบบภาษาไทยและภาษาอังกฤษ',
  },
  {
    active: true,
    type: 'dpa',
    moreInfoRoute: 'serviceDPA',
    title: 'Data Processing Agreement',
    shortenTitle: 'DPA',
    content: [
      'ธุรกิจต้องจัดทำ Data Processing Agreement หรือสัญญาประมวลผลข้อมูลส่วนบุคคลเมื่อมีการว่าจ้างผู้ประมวลผลข้อมูลส่วนบุคคล เช่น บริษัท A ว่าจ้างให้บริษัท B ทำการตลาดโดยใช้ข้อมูลส่วนบุคคลของลูกค้า',
    ],
    color: '#d89cf6',
    head: 'สิ่งที่คุณจะได้รับ',
    benefit: [
      {
        title:
          'Data Processing Agreement สอดคล้อง PDPA ของประเทศไทย และ GDPR ของสหภาพยุโรป',
      },
    ],
    plans: [
      {
        cost: '1,499.-',
        unit: 'ฉบับ',
        benefit: [true],
      },
    ],
    buttonText: 'สร้างสัญญาตอนนี้',
    aboveButtonText: 'ใน 1 ฉบับคุณจะได้รับสัญญาทั้งแบบภาษาไทยและภาษาอังกฤษ',
  },
  {
    active: true,
    type: 'consult',
    title: 'Consulting Service',
    content: [
      'สำหรับองค์กรที่ต้องการปรึกษาทุกข้อสงสัยเกี่ยวกับ PDPA Solution เรามี partner ที่สามารถให้การบริการท่านในส่วนนี้ได้ โดยจะเป็นการปรึกษากับผู้เชี่ยวชาญด้านกฎหมายโดยเฉพาะ เพื่อให้การทำ PDPA เป็นไปอย่างดีและตอบโจทย์ความต้องการขององค์กรมากที่สุด',
    ],
    color: '#95cd44',
    head: 'สิ่งที่คุณจะได้รับ',
    benefit: [
      {
        title: 'ปรึกษานักกฎหมายเกี่ยวกับระบบ PDPA (Private)',
      },
      {
        title: 'ตรวจสอบเอกสารต่างๆ ทางกฎหมายที่เกี่ยวข้องกับ PDPA',
      },
    ],
    plans: [
      {
        cost: '2,000.-',
        unit: '30 นาที',
        benefit: [true, true],
      },
    ],
  },
  {
    active: true,
    type: 'prokit',
    title: 'PDPA Prokit',
    content: [
      'เครื่องมือเตรียมพร้อม PDPA ในรูปแบบเอกสาร ฟอร์ม แผนภาพ ฯลฯ มากกว่า 69 ชุด',
      'ให้ธุรกิจของคุณปฏิบัติตาม PDPA ได้ง่ายๆ เพียงคลิกเดียว',
    ],
    color: '#eeb211',
    head: 'สิ่งที่คุณจะได้รับ',
    benefit: [
      {
        title: 'ข้อแนะนำการปฏิบัติตาม PDPA',
      },
      {
        title: 'เครื่องมือ Data Mapping',
      },
      {
        title: 'นโยบายต่างๆ',
      },
      {
        title: 'ขั้นตอนและกระบวนการดำเนินงานเพื่อให้สอดคล้องกับ PDPA',
      },
      {
        title:
          'เอกสารในรูปแบบ .docx .pdf และ .xlsx ที่สามารถปรับแต่งให้เหมาะสมกับองค์กรของคุณ',
      },
    ],
    plans: [
      {
        benefit: [true, true, true, true, true],
      },
    ],
  },
]

import { Stack, VStack, HStack, Tr } from '@chakra-ui/react'
import { animated } from 'react-spring'

export const AnimatedBox = animated(Stack)
export const AnimatedTr = animated(Tr)
export const AnimatedVStack = animated(VStack)
export const AnimatedHStack = animated(HStack)

export const DEFAULT_SPRING_CONFIG = {
  mass: 1,
  friction: 50,
  tension: 300,
}

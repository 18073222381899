import React from 'react'
import Image from 'next/image'
import {
  Stack,
  Text,
  VStack,
  AspectRatio,
  Container,
  Link,
} from '@chakra-ui/react'

import { AS_SEEN_ON } from './constants'

function AsSeenOn() {
  return (
    <Container
      as="section"
      sx={{
        minW: {
          base: '100%',
          md: '628px',
          'lg+': '960px',
        },
      }}
    >
      <VStack
        spacing={{
          base: 10,
          lg: 12,
        }}
        sx={{
          mt: { base: 16, md: '120px' },
          mb: 10,
          w: '100%',
        }}
      >
        <Text
          as="h1"
          sx={{
            fontSize: {
              base: '32px',
              md: '40px',
            },
            fontWeight: 'medium',
          }}
        >
          PDPA Pro ในสื่อต่างๆ
        </Text>
        <Stack
          spacing="10"
          direction={{
            base: 'column',
            md: 'row',
          }}
          sx={{
            h: {
              base: 'fit-content',
              md: '120px',
            },
            w: '100%',
            justifyContent: {
              base: 'flex-start',
              md: 'space-between',
            },
          }}
        >
          {AS_SEEN_ON.map((data) => {
            return (
              <Link
                key={data.href}
                isExternal
                href={data.href}
                sx={{
                  flex: '1 1 auto',
                }}
              >
                <AspectRatio ratio={data.ratio} sx={data.sx}>
                  <Image
                    src={data.image}
                    alt={data.alt}
                    layout="fill"
                    objectFit="contain"
                  />
                </AspectRatio>
              </Link>
            )
          })}
        </Stack>
      </VStack>
    </Container>
  )
}

export default AsSeenOn

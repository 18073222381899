import {
  AspectRatio,
  Box,
  Center,
  Container,
  Flex,
  Grid,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'
import Image from 'next/image'
import { Element } from 'react-scroll'

import { Section } from './WhatIsReferral.styles'
import { MotionBox } from '@/components/animations'

import { REFERRAL_STEPS, WHAT_IS_REFERRALS } from '../../constants/referral'
import { useInViewAnimation } from '../../utils'

export const WhatIsReferral = (
  props: ReferralSectionProps,
): React.ReactElement => {
  const { id } = props

  const [ref, controls] = useInViewAnimation()

  return (
    <Section as="section" id={id}>
      <Element name={id} />
      <Container className="container">
        <Stack className="heading-container" spacing={1}>
          <Heading as="h3">
            PDPA Pro&nbsp;
            <br />
            Referral Program&nbsp;
            <br />
            คืออะไร ?
          </Heading>
          <Text>
            PDPA Pro เปิดโอกาส ให้คุณมาร่วมเป็น Partner กับเรา
            <br />
            และสามารถสร้างรายได้กับเราง่าย ๆ เพียงแค่&nbsp;
            <Text as="span">แชร์โค้ด Referral</Text>&nbsp;
            <br />
            ที่ได้จากเราและ&nbsp;
            <Text as="span">รับค่าคอมมิชชัน 5%</Text>&nbsp;จากทุกยอดซื้อ
          </Text>
        </Stack>

        <Box className="content-container">
          {WHAT_IS_REFERRALS.map((datum, idx) => {
            const isOdd = idx % 2 !== 0

            return (
              <Flex
                key={idx}
                className="content-item"
                flexDir={{
                  base: 'column',
                  'lg+': isOdd ? 'row-reverse' : 'row',
                }}
              >
                <AspectRatio ratio={1 / 1}>
                  <Image src={datum.img} layout="fill" objectFit="contain" />
                </AspectRatio>

                <Stack className="content-item__contents">
                  {datum.title}
                  {datum.content}
                </Stack>
              </Flex>
            )
          })}
        </Box>

        <Box className="step-container" ref={ref}>
          <Heading as="h2">
            แค่ 4 ขั้นตอนเท่านั้น
            <br />
            เพื่อรับเงินกับเรา
          </Heading>
          <Grid className="step-container__grid">
            {REFERRAL_STEPS.map((step, idx) => {
              return (
                <MotionBox
                  className="step-container__grid-item"
                  key={idx}
                  custom={idx}
                  initial={{ y: 30, opacity: 0 }}
                  animate={controls}
                >
                  <Center className="grid-item__image">
                    <AspectRatio
                      ratio={step.ratio}
                      boxSize={{ base: '220px', 'lg+': '200px' }}
                    >
                      <Image
                        src={step.img}
                        layout="fill"
                        objectFit="contain"
                        alt={`${step.title} image`}
                      />
                    </AspectRatio>
                  </Center>
                  <Heading as="h4">{step.title}</Heading>
                  {step.content}
                </MotionBox>
              )
            })}
          </Grid>
        </Box>
      </Container>
    </Section>
  )
}

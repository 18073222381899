import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  Link,
  SimpleGrid,
  Stack,
  Textarea,
} from '@chakra-ui/react'

import { Link as NextLink } from '@/lib/Link'
import { useContactUsForm } from './methods'

export const ContactUsForm = () => {
  const { formik, isLoading } = useContactUsForm()

  return (
    <form onSubmit={formik.handleSubmit}>
      <SimpleGrid
        columns={{ base: 1, 'lg+': 2 }}
        w={{ base: '327px', md: '510px' }}
        mx={{ base: 'auto', 'lg+': 0 }}
        mt={{ base: '24px', md: '32px' }}
        gap="16px"
      >
        <FormControl isRequired isInvalid={!!formik.errors.first_name}>
          <FormLabel color="white">ชื่อจริง</FormLabel>
          <Input
            size="lg"
            placeholder="ชื่อจริง"
            name="first_name"
            color="black"
            fontSize={{ base: '14px' }}
            fontWeight={{ base: 300 }}
            value={formik.values.first_name}
            onChange={formik.handleChange}
            sx={{ background: 'white' }}
          />
          {formik.errors.first_name && (
            <FormErrorMessage>{formik.errors.first_name}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isRequired isInvalid={!!formik.errors.last_name}>
          <FormLabel color="white">นามสกุล</FormLabel>
          <Input
            size="lg"
            placeholder="นามสกุล"
            name="last_name"
            color="black"
            fontSize={{ base: '14px' }}
            fontWeight={{ base: 300 }}
            value={formik.values.last_name}
            onChange={formik.handleChange}
            sx={{ background: 'white' }}
          />
          {formik.errors.last_name && (
            <FormErrorMessage>{formik.errors.last_name}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isRequired isInvalid={!!formik.errors.company}>
          <FormLabel color="white">ชื่อบริษัท</FormLabel>
          <Input
            size="lg"
            placeholder="ชื่อบริษัท"
            name="company"
            color="black"
            fontSize={{ base: '14px' }}
            fontWeight={{ base: 300 }}
            value={formik.values.company}
            onChange={formik.handleChange}
            sx={{ background: 'white' }}
          />
          {formik.errors.company && (
            <FormErrorMessage>{formik.errors.company}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!formik.errors.position}>
          <FormLabel color="white">ตำแหน่งงาน (ถ้ามี)</FormLabel>
          <Input
            size="lg"
            placeholder="ตำแหน่งงาน (ถ้ามี)"
            name="position"
            color="black"
            fontSize={{ base: '14px' }}
            fontWeight={{ base: 300 }}
            value={formik.values.position}
            onChange={formik.handleChange}
            sx={{ background: 'white' }}
          />
          {formik.errors.position && (
            <FormErrorMessage>{formik.errors.position}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isRequired isInvalid={!!formik.errors.email}>
          <FormLabel color="white">อีเมล</FormLabel>
          <Input
            size="lg"
            placeholder="อีเมล"
            name="email"
            type="email"
            color="black"
            fontSize={{ base: '14px' }}
            fontWeight={{ base: 300 }}
            value={formik.values.email}
            onChange={formik.handleChange}
            sx={{ background: 'white' }}
          />
          {formik.errors.email && (
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isRequired isInvalid={!!formik.errors.tel}>
          <FormLabel color="white">เบอร์โทรศัพท์</FormLabel>
          <Input
            size="lg"
            placeholder="เบอร์โทรศัพท์"
            name="tel"
            type="tel"
            color="black"
            fontSize={{ base: '14px' }}
            fontWeight={{ base: 300 }}
            value={formik.values.tel}
            onChange={formik.handleChange}
            sx={{ background: 'white' }}
          />
          {formik.errors.tel && (
            <FormErrorMessage>{formik.errors.tel}</FormErrorMessage>
          )}
        </FormControl>

        <GridItem colSpan={{ base: 1, 'lg+': 2 }}>
          <FormControl isInvalid={!!formik.errors.message}>
            <FormLabel color="white">ข้อความที่อยากส่งถึงเรา (ถ้ามี)</FormLabel>
            <Textarea
              size="lg"
              minH={{ base: '108px' }}
              placeholder="ข้อความที่อยากส่งถึงเรา (ถ้ามี)"
              name="message"
              color="black"
              fontSize={{ base: '14px' }}
              fontWeight={{ base: 300 }}
              value={formik.values.message}
              onChange={formik.handleChange}
              resize="none"
              sx={{ background: 'white' }}
              focusBorderColor="brand.400"
            />
            {formik.errors.message && (
              <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 1, 'lg+': 2 }}>
          <Stack spacing={2}>
            <FormControl
              isRequired
              isInvalid={!!formik.errors.terms_and_policy_consent}
            >
              <Checkbox
                size="lg"
                name="terms_and_policy_consent"
                alignItems="flex-start"
                sx={{
                  fontWeight: 300,
                  a: {
                    color: 'WHITE',
                    textDecoration: 'underline',
                    fontWeight: 500,
                  },
                }}
                isChecked={formik.values.terms_and_policy_consent}
                onChange={formik.handleChange}
              >
                ฉันยอมรับ&nbsp;
                <NextLink route="terms" passHref>
                  <Link isExternal>เงื่อนไขในการใช้เว็บไซต์</Link>
                </NextLink>
                &nbsp;และ&nbsp;
                <NextLink route="privacy" passHref>
                  <Link isExternal>นโยบายความเป็นส่วนตัว</Link>
                </NextLink>
              </Checkbox>
              {formik.errors.terms_and_policy_consent && (
                <FormErrorMessage>
                  {formik.errors.terms_and_policy_consent}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={!!formik.errors.marketing_consent}>
              <Checkbox
                size="lg"
                name="marketing_consent"
                alignItems="flex-start"
                sx={{
                  fontWeight: 300,
                  a: {
                    color: 'WHITE',
                    textDecoration: 'underline',
                  },
                }}
                isChecked={formik.values.marketing_consent}
                onChange={formik.handleChange}
              >
                ฉันต้องการรับโปรโมชันและข่าวสารทางการตลาดเกี่ยวกับ PDPA Pro
                และบริการอื่น ๆ จากเรา บริษัทในเครือ
                บริษัทย่อยและพันธมิตรทางธุรกิจ &#8203;
                <span style={{ whiteSpace: 'nowrap' }}>
                  (คุณสามารถยกเลิกได้ทุกเมื่อ)
                </span>
              </Checkbox>
              {formik.errors.marketing_consent && (
                <FormErrorMessage>
                  {formik.errors.marketing_consent}
                </FormErrorMessage>
              )}
            </FormControl>
          </Stack>
        </GridItem>

        <GridItem colSpan={{ base: 1, 'lg+': 2 }}>
          <Button
            isLoading={isLoading}
            size="xl"
            colorScheme="primary"
            sx={{
              fontSize: '20px',
              mt: 6,
              w: 'full',
            }}
            type="submit"
          >
            ส่งข้อมูล
          </Button>
        </GridItem>
      </SimpleGrid>
    </form>
  )
}

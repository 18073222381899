import { Box, chakra } from '@chakra-ui/react'

export const Section = chakra(Box, {
  baseStyle: {
    bg: 'gray.100',
    py: {
      'lg+': '80px',
    },
    h2: {
      fontWeight: 'normal',
      lineHeight: 1.5,
      textAlign: 'center',
      fontSize: { base: '32px', 'lg+': '40px' },
      mb: '40px',
    },
    '.container': {
      width: '90%',
      py: '80px',
    },
    '.whyus-grid': {
      gridTemplateColumns: {
        md: 'repeat(2, 1fr)',
      },
      gridTemplateRows: {
        md: 'repeat(2, 1fr)',
        'lg+': 'unset',
      },
      gap: {
        base: '24px',
        md: '36px 24px',
        'lg+': '40px 44px',
      },
      '&__grid-item': {
        flexDir: {
          base: 'column',
          'lg+': 'row',
        },
        alignItems: {
          base: 'center',
          md: 'unset',
        },
        '.grid-item': {
          '&__image': {
            boxSize: '48px',
            flex: '0 0 48px',
            mb: {
              base: '16px',
              'lg+': 0,
            },
          },
          '&__contents': {
            ml: {
              'lg+': '24px',
            },
            textAlign: {
              base: 'center',
              md: 'start',
            },
          },
          '&__title': {
            fontSize: '24px',
          },
          '&__content': {
            fontWeight: 'light',
            fontSize: '20px',
          },
        },
      },
    },
  },
})

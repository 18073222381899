import { object, string, boolean } from 'yup'

export const contactFormSchema = object().shape({
  first_name: string().required(),
  last_name: string().required(),
  company: string().required(),
  email: string().email().required(),
  tel: string()
    .required()
    .matches(/^[0-9]+$/, 'กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก')
    .length(10, 'กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก'),
  terms_and_policy_consent: boolean().required(),
  marketing_consent: boolean(),
})

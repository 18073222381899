import { AspectRatio, Container, Grid, Heading, chakra } from '@chakra-ui/react'
import Image from 'next/image'
import { Fragment } from 'react'

import { REFERRAL_EXAMPLES } from '../../constants/referral'

import { useInViewAnimation } from '../../utils'

import { MotionAspectRatio, MotionStack } from '@/components/animations'
import { Section } from './ReferralExample.styles'

const ChakraImage = chakra(Image, {
  baseStyle: {
    transform: {
      base: 'rotate(90deg)',
      'lg+': 'none',
    },
  },
})

export const ReferralExample = (
  props: ReferralSectionProps,
): React.ReactElement => {
  const { id } = props

  const [ref, controls] = useInViewAnimation()

  return (
    <Section as="section" id={id} ref={ref}>
      <Container className="container">
        <Heading as="h2">ตัวอย่างการคำนวณรายได้</Heading>

        <Grid className="example-grid">
          {REFERRAL_EXAMPLES.map((ex, idx) => {
            return (
              <Fragment key={idx}>
                {idx !== 0 && (
                  <MotionAspectRatio
                    ratio={1 / 1}
                    boxSize="40px"
                    className="arrow"
                    custom={idx}
                    initial={{ y: 30, opacity: 0 }}
                    animate={controls}
                  >
                    <ChakraImage
                      src="/images/Landing/referral/CaretRight.png"
                      layout="fill"
                      objectFit="contain"
                      alt=""
                    />
                  </MotionAspectRatio>
                )}

                <MotionStack
                  key={idx}
                  className="example-grid__item"
                  spacing="4"
                  custom={idx}
                  initial={{ y: 30, opacity: 0 }}
                  animate={controls}
                >
                  <AspectRatio ratio={ex.ratio}>
                    <Image
                      src={ex.icon}
                      layout="fill"
                      objectFit="contain"
                      alt=""
                    />
                  </AspectRatio>

                  {ex.content}
                </MotionStack>
              </Fragment>
            )
          })}
        </Grid>
      </Container>
    </Section>
  )
}

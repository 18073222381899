import { Box, Text, Wrap, WrapItem, Center, VStack } from '@chakra-ui/react'
import Image from 'next/image'

import { Container } from '@/components/Landing/shared'

import { SITE_REFERENCE } from './constants'
import { Br, Span } from '@/components/shared'

function SiteReference(): React.ReactElement {
  return (
    <Box
      as="section"
      sx={{
        pt: '48px',
        pb: {
          base: '80px',
          md: '108px',
          'lg+': '160px',
        },
      }}
    >
      <Container>
        <VStack spacing="12">
          <Text
            as="h2"
            sx={{
              fontSize: { base: '32px', md: '40px' },
              fontWeight: 'medium',
            }}
          >
            ลูกค้าที่ไว้วางใจเรา
          </Text>
          <Wrap
            spacing="12"
            justify="center"
            sx={{
              alignItems: 'center',
              maxW: '753px',
            }}
          >
            {SITE_REFERENCE.map((site, index) => {
              return (
                <WrapItem
                  as={Center}
                  key={`${index}`}
                  width={{
                    base: `${site.width * 0.7}px`,
                    md: `${site.width}px`,
                  }}
                  height={{
                    base: `${site.height * 0.7}px`,
                    md: `${site.height}px`,
                  }}
                  alignSelf="center"
                >
                  <Image
                    src={site.src}
                    alt={site.alt}
                    objectFit="contain"
                    width={site.width}
                    height={site.height}
                  />
                </WrapItem>
              )
            })}
          </Wrap>

          <Text
            sx={{
              fontSize: {
                md: '20px',
                lg: '24px',
              },
            }}
          >
            และลูกค้าที่ไว้วางใจเรา
            <Span
              sx={{
                bgGradient: 'linear(to-l, #00E3AB, #32C4D7)',
                bgClip: 'text',
              }}
            >
              กว่า 5000 ราย
            </Span>
            <Br
              sx={{
                d: {
                  base: 'block',
                  'lg+': 'none',
                },
              }}
            />
            ทั้งลูกค้าองค์กรขนาดเล็กและขนาดใหญ่
          </Text>
        </VStack>
      </Container>
    </Box>
  )
}

export default SiteReference

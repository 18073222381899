import { useState } from 'react'
import { useFormik } from 'formik'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { acceptConsent } from '@/lib/utils/consent'
import { logEvent } from '@/lib/stats/gtm'
import env from '@/config/env'
import { checkWebsiteSchema } from './schema'

export function useCheckWebsiteForm() {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()

  const formik = useFormik({
    initialValues: {
      website: '',
      email: '',
      tel: '',
      terms_and_policy_consent: false,
      marketing_consent: false,
    },
    validationSchema: checkWebsiteSchema,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      await Promise.all([
        fetchAPI({
          method: 'POST',
          path: '/pdpa_compliance',
          data: values,
        }),

        values.marketing_consent
          ? acceptConsent({
              apiKey: env.CONSENT_WOW_CHECK_WEBSITE_API_KEY,
              purposeId: env.CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID,
              data: {
                ...values,
                phone_number: values.tel,
              },
            })
          : Promise.resolve(null),
      ])
        .then(() => {
          toast({
            title:
              'ขอบคุณที่สนใจในผลิตภัณฑ์ของเรา เราจะติดต่อคุณกลับไปในไม่ช้า',
            status: 'success',
            position: 'top-right',
          })

          logEvent({
            ga: {
              category: 'Landing Page',
              action: 'Check website submit success',
            },
            fb: {
              event: 'Lead',
            },
          })

          resetForm()
          setLoading(false)
        })
        .catch(() => {
          toast({
            title: 'มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่ในภายหลัง',
            status: 'error',
            position: 'top-right',
          })
          setLoading(false)
        })
    },
  })

  return {
    formik,
    isLoading,
  }
}

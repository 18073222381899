export const links = [
  {
    name: 'หน้าแรก',
    path: '/',
    hash: '#Hero',
    displayPath: '/',
  },
  {
    name: 'เริ่มต้นและวิธีการใช้',
    path: '/',
    hash: '#GetStarted',
  },
  {
    name: 'ทำไมต้องมี Privacy Policy',
    path: '/',
    hash: '#WhatIsPrivacyPolicy',
  },
  {
    name: 'PDPA Blog',
    path: '/blogs',
  },
  {
    name: 'ทำไมต้อง PDPA Pro',
    path: '/',
    hash: '#WhyPDPAPro',
  },
  {
    name: 'FAQs',
    path: '/faq',
  },
  {
    name: 'ราคาและแพ็กเกจ',
    path: '/',
    hash: '#OurServices',
  },
  {
    name: 'ผลิตภัณฑ์ของเรา',
    path: '/our-products',
  },
  {
    name: 'Referral Program',
    path: '/referral',
  },
]

import React from 'react'
import { Flex, Box } from '@chakra-ui/react'
import Link from 'next/link'

import { mq, Mobile } from '@/lib/responsive'

import { Container, H2, P, Background } from '@/components/Landing/shared'
import { Link as CustomLink } from '@/features/home/components'

const Preparation = () => {
  return (
    <section
      css={{
        padding: '16px 0 0 0',
        '& strong': {
          fontWeight: 500,
        },
      }}
      id="WhatIsPrivacyPolicy"
    >
      <Container
        css={{
          position: 'relative',
          display: 'grid',
          justifyContent: 'center',
          gap: '8px',
          textAlign: 'center',
        }}
      >
        <Flex justifyContent="center">
          <Box w="56px" h="64px" alignSelf="center" pos="relative">
            <Background src="/images/Landing/sc-shield-icon.svg" />
          </Box>
        </Flex>
        <H2
          css={{
            fontSize: 24,
            lineHeight: 1.6,
            [mq('sm')]: { marginTop: '16px' },
            [mq('lg')]: {
              margin: 0,
              fontSize: 32,
              lineHeight: 1.6,
            },
          }}
        >
          เตรียมรับมือ PDPA อย่างไร?
        </H2>
        <P
          css={{
            fontWeight: 300,
            fontSize: 20,

            [mq('sm')]: {
              fontSize: 24,
            },

            '.default': {
              display: 'none',

              [mq('sm')]: {
                display: 'block',
              },
            },
          }}
        >
          เพื่อปฏิบัติตาม PDPA คุณต้องมี Policy ที่สอดคล้องกับธุรกิจ&nbsp;
          <br className="default" />
          <strong>
            <CustomLink path="/" hash="#OurServices" css={{ color: '#ff7315' }}>
              {({ onClick }) => <a onClick={onClick}>บริการของเรา</a>}
            </CustomLink>
          </strong>
          &nbsp;ให้คุณสร้างแบบฟอร์ม Privacy Policy ได้ง่ายๆ ในไม่กี่นาที
          <br className="default" />
          PDPA Pro รับทำนโยบาย PDPA ในราคาที่คุ้มค่า
          ตอบโจทย์ทุกความต้องการในการใช้งาน
        </P>
        <Box
          position="relative"
          textAlign="left"
          css={{
            paddingTop: 139,
            paddingBottom: 91,
            [mq('sm')]: {
              padding: 0,
              marginTop: 64,
              width: 768,
            },
            [mq('md+')]: {
              width: 1200,
              margin: 0,
            },
          }}
        >
          <Box
            position="absolute"
            w="928px"
            h="600px"
            zIndex="-1"
            transform="translate(-250px, -139px)"
            css={{
              [mq('sm')]: { display: 'none' },
            }}
          >
            <Background
              src="/images/Landing/blob.svg"
              objectFit="contain"
              objectPosition="center"
            />
          </Box>
          <Box
            color="#ffffff"
            css={{
              width: 375,
              padding: '0 33px',

              [mq('sm')]: {
                padding: '131px 100px 0 140px',
                width: 928,
                height: 600,
                transform: 'translateX(-100px)',
              },
              [mq('md+')]: {
                padding: '204px 104px 191px 119px',
                transform: 'none',
                width: 985,
                height: 687,
              },
            }}
          >
            <Box
              pos="absolute"
              zIndex={-1}
              top={0}
              css={{
                width: 375,
                padding: '0 33px',

                [mq('sm')]: {
                  padding: '131px 100px 0 140px',
                  width: 928,
                  height: 600,
                  transform: 'translateX(-100px)',
                },
                [mq('md+')]: {
                  padding: '204px 104px 191px 119px',
                  width: 985,
                  height: 687,
                },
              }}
            >
              <Background
                src="/images/Landing/blob.svg"
                objectFit="contain"
                objectPosition="left"
              />
            </Box>
            <P
              css={{
                fontSize: 24,
                lineHeight: 1.6,
                [mq('sm')]: {
                  fontSize: 40,
                  lineHeight: 1.6,
                },
              }}
            >
              <strong>
                <span css={{ textDecoration: 'underline' }}>ต้องมี</span>{' '}
                Privacy Policy
                <br />
                หากธุรกิจคุณเก็บข้อมูลผู้ใช้งาน
              </strong>
            </P>
            <P
              css={{
                fontSize: 20,
                marginTop: 16,

                [mq('sm')]: {
                  fontSize: 24,
                },
              }}
            >
              Privacy Policy หรือนโยบายความเป็นส่วนตัว รวมถึงนโยบายอื่นๆ ที่
              <Mobile>
                <br />
              </Mobile>
              เกี่ยวกับข้อมูลส่วนบุคคล{' '}
              <strong>
                มีความจำเป็นตาม พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 (PDPA)
              </strong>{' '}
              <strong>
                หากไม่ปฏิบัติตามอาจ มีโทษปรับสูงสุด 5 ล้านบาท จำคุกไม่เกิน 1 ปี
                หรือ ทั้งจำทั้งปรับ{' '}
                <Link href="/blogs/how-is-privacy-policy-important" passHref>
                  <a css={{ textDecoration: 'underline', color: 'inherit' }}>
                    เรียนรู้เพิ่มเติม
                  </a>
                </Link>
              </strong>
            </P>
          </Box>
          <Box
            position="absolute"
            css={{
              [mq('md+')]: {
                right: -120,
                bottom: 0,
                width: 495,
                height: 616,
              },
            }}
          >
            <Background
              src="/images/Landing/pdpa-pro-privacy-desktop.svg"
              objectFit="contain"
            />
          </Box>
        </Box>
      </Container>
    </section>
  )
}

export default Preparation

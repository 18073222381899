import { Link } from '@/lib/Link'
import { Link as ChakraLink } from '@chakra-ui/react'

type TagProps = {
  name: string
  tagId: number
}

export const Tag = (props: TagProps) => {
  const { name, tagId } = props
  return (
    <Link route="blogsTag" params={{ tagId }} passHref>
      <ChakraLink
        sx={{
          fontSize: '10px',
          fontWeight: 500,
          color: 'customGray.900',
          border: 'solid 1px',
          borderRadius: '4px',
          padding: '2px 4px',
          _hover: {
            color: 'primary.500',
            textDecoration: 'none',
          },
        }}
      >
        {name}
      </ChakraLink>
    </Link>
  )
}

export const AS_SEEN_ON = [
  {
    href: 'https://techsauce.co/tech-and-biz/pdpa-for-business',
    sx: {
      h: {
        base: '44px',
        md: '100%',
      },
    },
    image: '/images/Landing/logo-techsauce.png',
    alt: 'Techsauce Logo',
    ratio: 334 / 63,
  },
  {
    href:
      'https://www.everydaymarketing.co/business/data/cookie-consent-banner-pdpa-data-driven-marketing-cookie-wow/',
    sx: {
      h: {
        base: '120px',
        md: '100%',
      },
    },
    image: '/images/Landing/logo-everyday-market.png',
    alt: 'Everyday Market Logo',
    ratio: 129 / 92,
  },
  {
    href:
      'https://contentshifu.com/blog/cookie-consent-banner?utm_campaign=Sponsorship&utm_content=160329008&utm_medium=social&utm_source=facebook&hss_channel=fbp-322908168042215',
    sx: {
      h: {
        base: '64px',
        md: '100%',
      },
    },
    image: '/images/Landing/logo-contentshifu.png',
    alt: 'Content Shifu Logo',
    ratio: 171 / 64,
  },
]

import { Box, chakra } from '@chakra-ui/react'

export const Section = chakra(Box, {
  baseStyle: {
    bg: '#0d1d2e',
    color: 'white',
    py: '80px',
    h2: {
      fontWeight: 'normal',
      lineHeight: 1.5,
      textAlign: 'center',
      fontSize: { base: '32px', 'lg+': '40px' },
      mb: '36px',
    },
    '.container': {
      width: '95%',
    },
    '.example-grid': {
      gap: '8px',
      justifyContent: { base: 'center', 'lg+': 'space-around' },
      justifyItems: {
        base: 'center',
        'lg+': 'unset',
      },
      gridTemplateColumns: {
        'lg+': '1fr 40px 1fr 40px max-content',
      },
      '.arrow': {
        alignSelf: 'center',
      },
      '&__item': {
        alignItems: 'center',
        bg: '#091420',
        borderRadius: '20px',
        minW: {
          base: '220px',
          'lg+': 'unset',
        },
        p: {
          base: '24px',
          md: '32px',
        },
        '> *:first-of-type': {
          w: { base: '72px', 'lg+': '96px' },
          minH: {
            'lg+': '96px',
          },
        },
      },
    },
    '.grid-item': {
      '&__contents': {
        textAlign: 'center',
      },
      '&__title': {
        fontSize: {
          base: '20px',
          md: '28px',
        },
      },
      '&__description': {
        fontWeight: 'light',
        fontSize: {
          base: '16px',
          md: '20px',
        },
      },
    },
  },
})

import { Box, chakra } from '@chakra-ui/react'

export const Section = chakra(Box, {
  baseStyle: {
    '.container': {
      width: '95%',
      pt: {
        base: '120px',
        'lg+': '120px',
      },
      pb: {
        base: '96px',
        'lg+': '96px',
      },
    },
    h2: {
      fontWeight: 'normal',
      lineHeight: 1.5,
      textAlign: 'center',
      fontSize: { base: '52px', 'lg+': '52px' },
      mb: {
        base: '48px',
        'lg+': '48px',
      },
    },
    '.form-wrapper': {
      pos: 'relative',
      mx: 'auto',
      bg: 'white',
      border: '1px solid',
      borderColor: 'gray.100',
      borderRadius: '20px',
      boxShadow: '0 0 10px 0 #0000001A',
      maxW: {
        'lg+': '800px',
      },
      p: {
        base: '40px',
        'lg+': '40px',
      },
      mb: {
        base: '80px',
        md: '120px',
      },
    },
    form: {
      '.form-container': {
        gap: {
          base: '24px',
          'lg+': '24px',
        },
        gridTemplateColumns: {
          md: 'repeat(2, 1fr)',
        },
        a: {
          color: 'brand.400',
          fontWeight: 'bold',
          textDecor: 'underline',
        },
        label: {
          '.optional': {
            color: 'gray.500',
          },
        },
      },
    },
    '.partner-banner': {
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      p: {
        fontSize: {
          base: '24px',
          md: '36px',
        },
      },
      '&-image': {
        boxSize: {
          base: '220px',
          'lg+': '230px',
        },
      },
    },
  },
})

import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import { Colors } from '@/components/Landing/shared'
import { mq } from '@/lib/responsive'

export const NavItem = styled.div(
  ({ altStyle, css }: { altStyle: boolean; css?: CSSProperties }) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '0px 16px',
    width: '100%',

    transition: '100ms color, 100ms background-color',

    ...(altStyle
      ? {
          color: Colors.JAGUAR_BLACK,
          backgroundColor: 'transparent',
          '&:hover': {
            textDecoration: 'none',
          },
          [mq('md')]: {
            borderBottom: `1px solid transparent`,
            padding: '12px 24px',
            '&:hover': {
              color: Colors.WHITE,
              backgroundColor: '#1B2938',
            },
          },
        }
      : {
          color: Colors.WHITE,
          '&:hover': {
            textDecoration: 'none',
          },
          [mq('md')]: {
            borderBottom: `1px solid transparent`,
            padding: '12px 24px',
            '&:hover': {
              color: Colors.JAGUAR_BLACK,
              backgroundColor: '#FFFFFF7F',
            },
          },
        }),
    ...css,
  }),
)

export const SocialButton = styled.span(
  ({ altStyle, isLine }: { altStyle: boolean; isLine: boolean }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '60px',
    transition: 'background-color 0.2s ease',
    backgroundColor: altStyle
      ? isLine
        ? '#00b900'
        : '#3b5999'
      : 'transparent',

    ...(!altStyle
      ? {
          color: Colors.WHITE,
          [mq('md')]: {
            width: '72px',
            '&:hover': {
              color: Colors.JAGUAR_BLACK,
              backgroundColor: '#FFFFFF7F',
            },
          },
        }
      : {
          [mq('md')]: {
            width: '72px',
          },
        }),
  }),
)

export const headerCommonProps = (altStyle: boolean) => {
  return {
    width: '100%',
    height: '60px',
    position: 'fixed',
    zIndex: 10,
    backgroundColor: altStyle ? Colors.WHITE : '#0000005f',
    transition: 'background-color 0.2s, box-shadow 0.2s',
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      width: '100%',
      height: '100%',
      backdropFilter: altStyle ? 'unset' : 'blur(16px)',
      boxShadow: `inset 0 -1px 0 0 ${altStyle ? '#e8e8e8' : '#00000000'}`,
    },
  }
}

import { Box, chakra, Grid, Image, Text } from '@chakra-ui/react'

import { ContactUsForm } from './ContactUsForm'

const Br = chakra('br')

const ContactUs = () => {
  return (
    <Box
      id="ContactUs"
      as="section"
      mb={{ base: 0, md: '-60px' }}
      bgColor="DARK_BLUE"
    >
      <Grid gridTemplateColumns={{ base: '1fr', 'lg+': '45% 1fr' }}>
        <Image
          src="/images/Landing/contact-bg.png"
          w="full"
          h="full"
          objectFit="cover"
          display={{ base: 'none', 'lg+': 'block' }}
        />

        <Box
          sx={{
            pl: { base: 0, md: '92px' },
            pt: { base: '60px', 'lg+': '72px' },
            pb: '96px',
            pr: { base: 0, md: '92px', 'lg+': 0 },
            color: 'white',
            bgColor: 'DARK_BLUE',
          }}
        >
          <Text
            sx={{
              fontSize: '40px',
              textAlign: { base: 'center', 'lg+': 'left' },
            }}
          >
            ติดต่อเรา
          </Text>
          <Text
            sx={{
              fontSize: '18px',
              textAlign: { base: 'center', 'lg+': 'left' },
              fontWeight: 300,
            }}
          >
            หากคุณสนใจ&nbsp;
            <Text as="span" sx={{ fontWeight: 500 }}>
              PDPA Pro
            </Text>
            <Br display={{ base: 'block', md: 'none' }} />
            &nbsp;หรือต้องการสอบถามข้อมูลเพิ่มเติม <br />
            คุณสามารถกรอกข้อมูลที่ฟอร์มด้านล่าง&nbsp;
            <Br display={{ base: 'block', md: 'none' }} />
            เราจะติดต่อกลับภายใน 24 ชั่วโมง
          </Text>

          <ContactUsForm />
        </Box>
      </Grid>
    </Box>
  )
}

export default ContactUs

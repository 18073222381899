import { AspectRatio, Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import Image from 'next/image'
import NextLink from 'next/link'

import { logEvent } from '@/lib/stats/gtm'

export const ReferralBanner = (): React.ReactElement => {
  return (
    <Flex
      sx={{
        w: {
          base: '312px',
          md: '655px',
        },
        h: {
          base: '268px',
          md: '204px',
        },
        pos: 'relative',
        borderRadius: '16px',
        bg: 'transparent',
      }}
    >
      <Box sx={{ pos: 'absolute', boxSize: 'full' }}>
        <AspectRatio
          ratio={228 / 203}
          sx={{
            pos: 'absolute',
            left: 0,
            bottom: 0,
            w: {
              base: '123px',
              md: '227px',
            },
          }}
        >
          <Image
            src="/images/Landing/referral/referral-man.svg"
            layout="fill"
            objectFit="contain"
            alt=""
          />
        </AspectRatio>

        <AspectRatio
          ratio={134 / 88}
          sx={{
            pos: 'absolute',
            right: 0,
            bottom: 0,
            w: '122px',
          }}
        >
          <Image
            src="/images/Landing/referral/referral-leaves.svg"
            layout="fill"
            objectFit="contain"
            alt=""
          />
        </AspectRatio>
      </Box>

      <Flex
        sx={{
          w: 'full',
          borderRadius: '16px',
          bg: 'gray.100',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
          p: {
            base: '16px',
            md: '18px 28px',
          },
          pb: {
            base: '116px',
            md: 0,
          },
          h: {
            base: 'full',
            md: '148px',
          },
          mt: {
            md: 'auto',
          },
        }}
      >
        <Stack
          spacing={{ base: '6px', md: '7px' }}
          sx={{
            textAlign: 'center',
            w: { base: '188px', md: '400px' },
            mx: { base: 'auto', md: 'unset' },
            ml: { md: 'auto' },
            alignItems: 'center',
          }}
        >
          <Text
            sx={{
              fontSize: { base: '20px', md: '22px' },
            }}
          >
            เข้าร่วม PDPA Pro Referral Program
          </Text>
          <Text
            sx={{
              fontSize: {
                base: '14px',
                md: '16px',
              },
            }}
          >
            สร้างรายได้ง่าย ๆ เพียงแค่แชร์!
          </Text>

          <NextLink href="/referral" passHref>
            <Button
              as="a"
              sx={{
                w: '140px',
                bgImage: 'linear-gradient(293deg, #00e3ab, #32c4d7)',
                color: '#ffffff',
                _hover: {
                  bgImage: 'linear-gradient(293deg, #06DBA6, #35BED0)',
                },
                _active: {
                  bgImage: 'linear-gradient(293deg, #10CB9D, #3CB4C3)',
                },
              }}
              onClick={() => {
                logEvent({
                  ga: {
                    category: 'Landing Page',
                    action: 'Click Referral Program',
                  },
                })
              }}
            >
              เข้าร่วมเลย!
            </Button>
          </NextLink>
        </Stack>
      </Flex>
    </Flex>
  )
}

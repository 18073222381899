import React from 'react'

import { Container, Colors, ExternalLink } from '@/components/Landing/shared'
import { Link } from '@/lib/Link'
import { mq } from '@/lib/responsive'

const Footer = () => {
  return (
    <footer
      css={{
        fontFamily: 'Anakotmai',
        padding: '42px 0',
        color: Colors.JUMBO_GRAY,
        fontSize: '14px',
        borderTop: `1px solid ${Colors.WHISPER_GREY}`,

        [mq('sm')]: {
          padding: '0',
          height: '48px',
        },
      }}
    >
      <Container
        css={{
          display: 'grid',
          gridTemplateRows: 'repeat(4, max-content)',
          justifyContent: 'center',
          textAlign: 'center',
          gap: '30px',

          [mq('sm')]: {
            gap: 0,
            gridTemplateRows: 'unset',
            gridTemplateColumns: '1fr repeat(3, max-content)',
            textAlign: 'start',
            height: '100%',
            alignItems: 'center',
          },
        }}
      >
        <p
          css={{
            order: 1,
            [mq('sm')]: {
              order: 'unset',
            },
          }}
        >
          ©{new Date().getFullYear()} <strong>nDataThoth Limited</strong> All
          Rights Reserved.
        </p>

        <ExternalLink
          href="https://pdpa.pro/faq"
          css={{
            color: Colors.JUMBO_GRAY,
            [mq('sm')]: {
              order: 'unset',
              marginRight: '24px',
            },
          }}
        >
          Help
        </ExternalLink>

        <Link route="terms" passHref>
          <a
            css={{
              color: Colors.JUMBO_GRAY,
              [mq('sm')]: { marginRight: '24px' },
            }}
          >
            Terms of Use
          </a>
        </Link>

        <Link route="privacy" passHref>
          <a
            css={{
              color: Colors.JUMBO_GRAY,
            }}
          >
            Privacy Policy
          </a>
        </Link>
      </Container>
    </footer>
  )
}

export default Footer

import React from 'react'
import { Text, Flex, Image } from '@chakra-ui/react'

import { mq } from '@/lib/responsive'

const SkeletonText = ({ charCount = 0, color = '#cccccc' }) => {
  const arr = new Array(charCount).fill('\u00A0')

  return (
    <span css={{ backgroundColor: color, whiteSpace: 'normal' }}>
      {arr.join('')}
    </span>
  )
}

const policyTh = (
  <React.Fragment>
    <Text
      css={{
        fontSize: 9.6,
        lineHeight: '19.8px',

        [mq('sm')]: {
          fontSize: 16,
          lineHeight: '33px',
        },
      }}
    >
      นโยบายคุ้มครองข้อมูลส่วนบุคคลสำหรับลูกค้า
    </Text>
    <Text
      fontWeight="300"
      css={{
        fontSize: 7.2,
        lineHeight: '14.4px',

        [mq('sm')]: {
          fontSize: 12,
          lineHeight: '24px',
        },
      }}
    >
      <span css={{ fontWeight: 500 }}>PDPA Pro (“บริษัท”)</span>{' '}
      ตระหนักถึงความสำคัญของการคุ้มครองข้อมูล ส่วนบุคคลของท่าน
      โดยนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ได้อธิบายการเก็บรวบรวม ใช้
      และเปิดเผยข้อมูลส่วนบุคคล <SkeletonText charCount={40} />{' '}
      <SkeletonText charCount={100} /> <SkeletonText charCount={105} />
    </Text>
    <Text
      css={{
        fontSize: 7.2,
        lineHeight: '14.4px',

        [mq('sm')]: {
          fontSize: 12,
          lineHeight: '24px',
        },
      }}
    >
      ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 และกฎหมายที่เกี่ยวข้อง
    </Text>
    <br />
    <Text
      css={{
        fontSize: 9.6,
        lineHeight: '14.4px',

        [mq('sm')]: {
          fontSize: 16,
          lineHeight: '24px',
        },
      }}
    >
      การเก็บรวบรวมข้อมูลส่วนบุคคล
    </Text>
    <Text
      css={{
        fontSize: 7.2,
        lineHeight: '14.4px',

        [mq('sm')]: {
          fontSize: 12,
          lineHeight: '24px',
        },
      }}
    >
      บริษัทเก็บรวบรวมข้อมูลส่วนบุคคลที่ระบุตัวตนของท่านได้โดยตรง
    </Text>
  </React.Fragment>
)

const policyEn = (
  <React.Fragment>
    <Text
      css={{
        fontSize: 9.6,
        lineHeight: '19.8px',

        [mq('sm')]: {
          fontSize: 16,
          lineHeight: '33px',
        },
      }}
    >
      Privacy Policy for customer
    </Text>
    <Text
      fontWeight="300"
      css={{
        fontSize: 7.2,
        lineHeight: '14.4px',

        [mq('sm')]: {
          fontSize: 12,
          lineHeight: '24px',
        },
      }}
    >
      <span css={{ fontWeight: 500 }}>PDPA Pro (“Company”)</span> recognizes the
      importance of the protection of personal data. This Privacy Policy
      explains our practices regarding the collection, use and disclosure of
      personal data <SkeletonText charCount={80} />{' '}
      <SkeletonText charCount={100} /> <SkeletonText charCount={105} />
    </Text>
    <Text
      css={{
        fontSize: 7.2,
        lineHeight: '14.4px',

        [mq('sm')]: {
          fontSize: 12,
          lineHeight: '24px',
        },
      }}
    >
      in accordance with the Personal Data Protection Act B.E. 2562 (2019) and
      applicable law
    </Text>
    <br />
    <Text
      css={{
        fontSize: 9.6,
        lineHeight: '14.4px',

        [mq('sm')]: {
          fontSize: 16,
          lineHeight: '24px',
        },
      }}
    >
      Collection of personal data
    </Text>
    <Text
      css={{
        fontSize: 7.2,
        lineHeight: '14.4px',

        [mq('sm')]: {
          fontSize: 12,
          lineHeight: '24px',
        },
      }}
    >
      We collect your personal data that can directly identify you
    </Text>
  </React.Fragment>
)

const htmlTh = (
  <React.Fragment>
    <Text
      css={{
        fontSize: 9.6,
        lineHeight: '19.8px',

        [mq('sm')]: {
          fontSize: 16,
          lineHeight: '33px',
        },
      }}
    >
      {'<h1>นโยบายคุ้มครองข้อมูลส่วนบุคคล...</h1>'}
    </Text>
    <Text
      css={{
        fontSize: 7.2,
        lineHeight: '14.4px',

        [mq('sm')]: {
          fontSize: 14,
          lineHeight: '24px',
        },
      }}
    >
      <SkeletonText charCount={100} /> <SkeletonText charCount={80} />
    </Text>
    <Text
      css={{
        fontSize: 9.6,
        lineHeight: '19.8px',

        [mq('sm')]: {
          fontSize: 16,
          lineHeight: '33px',
        },
      }}
    >
      {'<h1>การเก็บรวบรวมข้อมูลส่วนบุคคล</h1>'}
    </Text>
    <Text
      css={{
        fontSize: 7.2,
        lineHeight: '14.4px',

        [mq('sm')]: {
          fontSize: 14,
          lineHeight: '24px',
        },
      }}
    >
      <SkeletonText charCount={100} /> <SkeletonText charCount={70} />{' '}
      <SkeletonText charCount={90} />
    </Text>
  </React.Fragment>
)

const htmlEn = (
  <React.Fragment>
    <Text
      css={{
        fontSize: 9.6,
        lineHeight: '19.8px',

        [mq('sm')]: {
          fontSize: 16,
          lineHeight: '33px',
        },
      }}
    >
      {'<h1>Privacy Policy for customer</h1>'}
    </Text>
    <Text
      css={{
        fontSize: 7.2,
        lineHeight: '14.4px',

        [mq('sm')]: {
          fontSize: 14,
          lineHeight: '24px',
        },
      }}
    >
      <SkeletonText charCount={100} /> <SkeletonText charCount={80} />
    </Text>
    <Text
      css={{
        fontSize: 9.6,
        lineHeight: '19.8px',

        [mq('sm')]: {
          fontSize: 16,
          lineHeight: '33px',
        },
      }}
    >
      {'<h1>Collection of personal data</h1>'}
    </Text>
    <Text
      css={{
        fontSize: 7.2,
        lineHeight: '14.4px',

        [mq('sm')]: {
          fontSize: 14,
          lineHeight: '24px',
        },
      }}
    >
      <SkeletonText charCount={100} /> <SkeletonText charCount={70} />{' '}
      <SkeletonText charCount={90} />
    </Text>
  </React.Fragment>
)

const downloadLogo = [
  '/images/Landing/txt.svg',
  '/images/Landing/pdf.svg',
  '/images/Landing/html.svg',
  '/images/Landing/doc.svg',
]

const download = (
  <Flex flexWrap="wrap" css={{ gap: 6 }}>
    {downloadLogo.map((logo, index) => (
      <Flex
        key={`download-${index}`}
        flex="1"
        rounded="4px"
        border="1px solid #e8e8e8"
        bg="#ffffff"
        justifyContent="center"
        alignItems="center"
        css={{
          width: 43.5,
          height: 48.5,

          [mq('sm')]: {
            width: 87,
            height: 97,
          },
        }}
      >
        <Image src={logo} boxSize="75%" objectFit="contain" loading="lazy" />
      </Flex>
    ))}
  </Flex>
)

export const tabs = [
  {
    button: 'ดู Policy',
    content: {
      th: policyTh,
      en: policyEn,
    },
    action: 'Demo-View policy',
  },
  {
    button: 'HTML Code',
    content: {
      th: htmlTh,
      en: htmlEn,
    },
    action: 'Demo-HTML code',
  },
  {
    button: 'ดาวน์โหลดไฟล์',
    content: {
      th: download,
      en: download,
    },
    action: 'Demo-Download file',
  },
]

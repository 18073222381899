import React, { useState, useEffect } from 'react'
import { get } from 'lodash'

import { Link } from '@/lib/Link'
import { mq, LargeDesktop, useMQ } from '@/lib/responsive'
import { fetchAPI } from '@/lib/api'
import envObj from '@/config/env'

import {
  Container,
  H2,
  P,
  Colors,
  Button,
  Background,
} from '@/components/Landing/shared'
import BlogItem from './BlogItem'

const Blog = () => {
  const [blogs, setBlogs] = useState([])

  const { isMobile, isTablet, isDesktopPlus, isLargeDesktop } = useMQ()

  const getBlogs = async () => {
    const res = await fetchAPI({
      apiUrl: envObj.BLOG_API_URL,
      path: '/pdpa/blogs',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Key': envObj.BLOG_API_KEY,
      },
      responseType: 'json',
      params: {
        page: 1,
        per: 6,
      },
    })

    const resBlogs = get(res, 'data.data', [])

    setBlogs(resBlogs)
  }

  useEffect(() => {
    getBlogs()
  }, [])

  return (
    <section
      id="Blog"
      css={{
        padding: '72px 0',

        [mq('sm')]: {
          padding: '121px 0 72px',
        },

        [mq('lg')]: {
          padding: '72px 0 72px',
        },
      }}
    >
      <Container
        css={{
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <H2 css={{ marginBottom: '8px', [mq('lg')]: { marginBottom: 0 } }}>
          PDPA Blog
        </H2>

        <P
          css={{
            textAlign: 'center',
            width: '274px',
            margin: '0 auto 24px',

            [mq('sm')]: {
              width: 'unset',
              margin: '0 auto 42px',
            },

            [mq('lg')]: {
              marginBottom: '40px',
            },
          }}
        >
          ติดตามบทความ ความรู้เกี่ยวกับ PDPA เพิ่มเติม ที่นี่...
        </P>

        <div
          css={{
            display: 'grid',
            gap: '40px',
            marginBottom: '56px',

            [mq('sm')]: {
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: '56px 24px',
            },

            [mq('md+')]: {
              gridTemplateColumns: 'repeat(3, 354px)',
              gap: '26px',
              marginBottom: '64px',
            },
          }}
        >
          {blogs
            .filter((_, idx) => {
              if (isMobile && idx >= 2) {
                return false
              }

              if ((isDesktopPlus || isTablet) && idx >= 4) {
                return false
              }

              if (isLargeDesktop && idx >= 6) {
                return false
              }

              return true
            })
            .map((bl, idx) => (
              <BlogItem
                key={idx}
                image={get(bl, 'image_1.url')}
                title={get(bl, 'title')}
                date={get(bl, 'published_at') || get(bl, 'created_at')}
                link={`/blogs/${get(bl, 'slug')}`}
                tags={get(bl, 'tags', [])}
              />
            ))}
        </div>

        <Link route="blogs" passHref>
          <Button
            as="a"
            css={{
              border: `1px solid ${Colors.PRIMARY_DEFAULT}`,
              color: Colors.PRIMARY_DEFAULT,
              fontSize: '20px',
              backgroundColor: Colors.WHITE,
              width: '100%',

              [mq('sm')]: {
                width: '232px',
              },

              [mq('lg')]: {
                fontSize: '24px',
                width: 'auto',
              },
            }}
          >
            อ่านบทความอื่นๆ
          </Button>
        </Link>

        <LargeDesktop>
          <div
            css={{
              width: '181px',
              height: '172px',
              position: 'absolute',
              bottom: '-9px',
              right: '-121px',
              transform: 'translateX(100%)',
            }}
          >
            <Background src="/images/Landing/blog-bg.svg" objectFit="contain" />
          </div>
        </LargeDesktop>
      </Container>
    </section>
  )
}

export default Blog

export const PRODUCTS = [
  {
    bg: '#203E4A44',
    image: {
      src: '/images/Landing/other-products/consentwow-section.svg',
      alt: 'Consent Wow',
    },
    href: 'https://consentwow.com',
    action: 'Click consent wow',
  },
  {
    bgColor: '#EA834F44',
    image: {
      src: '/images/Landing/other-products/cookie-section.svg',
      alt: 'Cookie Wow',
    },
    href: 'https://cookiewow.com',
    action: 'Click cookie wow',
  },
  {
    bgColor: '#00254C44',
    image: {
      src: '/images/Landing/other-products/prokit-section.svg',
      alt: 'PDPA Prokit',
    },
    href: 'https://pdpa.pro/prokit',
    action: 'Click pdpa prokit',
  },
  {
    bgColor: '#2879F944',
    image: {
      src: '/images/Landing/other-products/pdpaform-section.svg',
      alt: 'PDPA Form',
    },
    href: 'https://pdpaform.com',
    action: 'Click pdpa form',
  },
  {
    bgColor: '#104EBC44',
    image: {
      src: '/images/Landing/other-products/learn-pdpa-section.svg',
      alt: 'LearnPDPA',
    },
    href: 'https://learnpdpa.com',
    action: 'Click learn pdpa',
  },
]

import { chakra } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Colors } from '@/components/Landing/shared'

export const Br = chakra('br')

export const List = styled.ul`
  padding-left: 24px;
  display: grid;
  grid-auto-rows: max-content;
  gap: 8px;
  margin-bottom: 24px;

  li {
    display: flex;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    &::before {
      content: '';
      min-width: 4px;
      height: 4px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 12px;
      background-color: ${Colors.JAGUAR_BLACK};
      margin-top: 0.6em;
    }
  }
`

/* eslint-disable react/prop-types, @typescript-eslint/explicit-module-boundary-types */

import { Grid, Flex, Box, Heading, Text } from '@chakra-ui/react'
import { darken, transparentize } from '@chakra-ui/theme-tools'

import { Link } from '@/lib/Link'
import { mq, Mobile, MediumPlusDesktop } from '@/lib/responsive'
import { Container, Button, Background } from '@/components/Landing/shared'
import { logEvent } from '@/lib/stats/gtm'
import { Colors } from '@/lib/styles'

import Interactive from './Interactive'

const content = [
  {
    title: 'Free Hosting',
    description: 'Policy ออนไลน์ที่เราโฮสต์ให้ สามารถคัดลอกลิงก์ไปใช้ได้เลย',
    active: true,
  },
  {
    title: 'HTML Code',
    description: 'Policy แบบ HTML Code นำไปปรับใช้ให้เข้ากับเว็บไซต์คุณได้',
    active: true,
  },
  {
    title: 'Dashboard',
    description: 'คุณสามารถจัดการทุก Policy ที่สร้างไว้ได้ทุกที่ทุกเวลา',
    active: true,
  },
  {
    title: 'รูปแบบสองภาษา',
    description: 'ภาษาไทยและภาษาอังกฤษ',
    active: true,
  },
  {
    title: 'ดาวน์โหลดไฟล์ .PDF, .TXT, .Docs',
    active: true,
  },
  {
    title: 'Custom Clause',
    description: 'ให้คุณเพิ่มเนื้อหา Policy ตามต้องการได้',
    active: true,
    disclaimer: '*เฉพาะ Policy แบบ premium เท่านั้น',
  },
]

const CheckIcon = ({ active }) => {
  const image = active ? 'icon-color-check.svg' : 'icon-disabled-check.svg'

  return (
    <Box w="20px" h="32px" pos="relative">
      <Background
        src={`/images/Landing/${image}`}
        objectFit="contain"
        css={{ zIndex: 'unset' }}
      />
    </Box>
  )
}

const WhatYouGet = ({
  primaryColor = Colors.PRIMARY_DEFAULT,
  hideDecor = false,
}) => {
  return (
    <section
      css={{
        // backgroundColor: Colors.WHITE,
        position: 'relative',

        [mq('sm')]: {
          paddingBottom: 40,
        },

        [mq('md+')]: {
          paddingBottom: 174,
        },
      }}
      id="WhatYouGet"
    >
      <Container>
        <Grid
          css={{
            rowGap: 24,

            [mq('sm')]: {
              rowGap: 56,
            },
          }}
        >
          <Box textAlign="center">
            <Text
              css={{
                fontSize: 16,

                [mq('sm')]: {
                  fontSize: 20,
                },
              }}
            >
              คุณจะได้รับทั้งหมดดังนี้
            </Text>
            <Heading
              as="h2"
              fontWeight="500"
              css={{
                fontSize: 32,

                [mq('sm')]: {
                  fontSize: 40,
                },
              }}
            >
              จ่ายแค่ครั้งเดียว
              <Mobile>
                <br />
              </Mobile>
              แต่คุ้มค่ายิ่งกว่า
            </Heading>
          </Box>
          <Grid
            justifyContent="center"
            css={{
              gridTemplateRows: 'repeat(2, max-content)',
              gap: 40,

              [mq('md+')]: {
                gridTemplateRows: 'unset',
                gridTemplateColumns: 'repeat(2, max-content)',
                gap: 24,
              },
            }}
          >
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              css={{
                gridRow: '2',

                [mq('sm')]: {
                  height: 460,
                },
                [mq('md+')]: {
                  height: 595,
                  gridColumn: '1',
                  gridRow: 'none',
                },
              }}
            >
              <Grid
                rowGap="16px"
                css={{
                  gridTemplateColumns: 'max-content',
                  justifyContent: 'center',

                  [mq('sm')]: {
                    gridTemplateColumns: 'repeat(2, max-content)',
                  },
                  [mq('md+')]: {
                    gridTemplateColumns: 'max-content',
                  },
                }}
              >
                {content.map(
                  ({ title, description, active, disclaimer }, index) => (
                    <Grid
                      columnGap="12px"
                      key={index}
                      css={{
                        gridTemplateColumns: 'max-content 279px',

                        [mq('sm')]: {
                          gridTemplateColumns: 'max-content 300px',
                        },
                        [mq('md+')]: {
                          gridTemplateColumns: 'max-content 1fr',
                        },
                      }}
                    >
                      <CheckIcon active={active} />
                      <Flex
                        flexDirection="column"
                        color={active ? Colors.JAGUAR_BLACK : Colors.IRON_GREY}
                      >
                        <Text
                          css={{
                            fontSize: 20,

                            [mq('sm')]: {
                              fontSize: 24,
                            },
                          }}
                        >
                          {title}
                        </Text>
                        {description && (
                          <Text fontSize="20px" fontWeight="300">
                            {description}
                          </Text>
                        )}
                        {disclaimer && (
                          <Text
                            as="small"
                            sx={{
                              fontWeight: 300,
                              color: 'gray.600',
                              fontSize: 'xs',
                            }}
                          >
                            {disclaimer}
                          </Text>
                        )}
                      </Flex>
                    </Grid>
                  ),
                )}
              </Grid>
              <Grid
                css={{
                  gap: 16,
                  marginTop: '33px',
                  marginBottom: '82px',

                  [mq('sm')]: {
                    gridAutoFlow: 'column',
                  },
                }}
              >
                <Link route="selectPolicy" passHref>
                  <Button
                    as="a"
                    sx={{
                      color: 'white',
                      fontSize: {
                        base: 'lg',
                        md: '2xl',
                      },
                      backgroundColor: primaryColor,
                      '&:hover': {
                        backgroundColor: darken(primaryColor, 10),
                      },
                    }}
                    onClick={() => {
                      logEvent({
                        ga: {
                          category: 'Landing Page-Demo',
                          action: 'Click create policy',
                        },
                      })
                    }}
                  >
                    สร้าง Policy ตอนนี้!
                  </Button>
                </Link>
                <Link route="faq" passHref>
                  <Button
                    as="a"
                    variant="outline"
                    sx={{
                      color: primaryColor,
                      borderColor: 'currentColor',
                      fontSize: {
                        base: 'lg',
                        md: '2xl',
                      },
                      backgroundColor: 'none',
                      '&:hover': {
                        color: primaryColor,
                        backgroundColor: transparentize(primaryColor, 0.2),
                      },
                    }}
                    onClick={() => {
                      logEvent({
                        ga: {
                          category: 'Landing Page-Demo',
                          action: 'Demo-Click FAQ',
                        },
                      })
                    }}
                  >
                    คำถามที่พบบ่อย
                  </Button>
                </Link>
              </Grid>
            </Flex>
            {!hideDecor && (
              <MediumPlusDesktop>
                <Box
                  position="absolute"
                  w="391px"
                  h="348px"
                  css={{
                    zIndex: 0,
                    [mq('md+')]: {
                      right: -50,
                      bottom: -43,
                    },
                    [mq('lg')]: {
                      right: 300,
                      bottom: -43,
                    },
                  }}
                >
                  <Background
                    src="/images/Landing/img-circles-2.svg"
                    objectFit="contain"
                  />
                </Box>
              </MediumPlusDesktop>
            )}
            <Interactive
              css={{
                zIndex: 0,
                margin: '0 auto',
                gridRow: '1',
                [mq('md+')]: {
                  gridRow: 'none',
                  gridColumn: '2',
                },
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default WhatYouGet

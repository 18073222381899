import axios from 'axios'

import envObj from '@/config/env'

interface GetConsentProps {
  apiKey: string
}

export async function getConsents({ apiKey }: GetConsentProps) {
  const res = await axios.get(envObj.CONSENT_WOW_API_URL, {
    headers: {
      Authorization: apiKey,
    },
  })
  return res.data
}

import React, { useState, useRef } from 'react'
import { Grid, Flex, Box, Text, Button, useToast } from '@chakra-ui/react'

import { mq } from '@/lib/responsive'
import { logEvent } from '@/lib/stats/gtm'
import { tabs } from './constant'

const Circle = ({ color }) => (
  <Box
    bg={color}
    rounded="50%"
    css={{
      width: 9,
      height: 9,

      [mq('sm')]: {
        width: 15,
        height: 15,
      },
    }}
  />
)

const LangSelect = ({ label, lang, selected, onClick }) => (
  <Text
    onClick={() => onClick(lang)}
    color={selected === lang && '#ff7315'}
    cursor="pointer"
  >
    {label}
  </Text>
)

const activeStyle = {
  bgImage: 'linear-gradient(293deg, #00e3ab, #32c4d7)',
  color: '#ffffff',
}

const inactiveStyle = {
  bg: '#ffffff',
  border: '1px solid #e8e8e8',
}

const Interactive = ({ className }) => {
  const [lang, setLang] = useState('th')
  const [selectedIndex, setSelectedIndex] = useState(0)

  const linkRef = useRef()

  const toast = useToast()

  const copyToClipboard = (ref) => {
    if (!ref.current) return

    const input = document.createElement('input')
    input.value = ref.current.innerText
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    toast({
      status: 'success',
      title: 'Copied to clipboard!',
      position: 'top-right',
    })
  }

  const selectedTab = tabs[selectedIndex]

  return (
    <Flex
      className={className}
      flexDirection="column"
      css={{
        width: 271,
        borderRadius: 4.8,
        border: '0.6px solid #e8e8e8',

        [mq('sm')]: {
          width: 452,
          borderRadius: 8,
          borderWidth: 1,
        },
      }}
    >
      <Grid
        bg="#edf1f5"
        alignItems="center"
        templateColumns="repeat(3, max-content) 1fr"
        css={{
          columnGap: 5,
          padding: '5px 10px 5px 6px',
          height: 28,

          [mq('sm')]: {
            columnGap: 8,
            padding: '10px 16px 10px 9px',
            height: 47,
          },
        }}
      >
        <Circle color="#ff6057" />
        <Circle color="#ffc12e" />
        <Circle color="#28ca40" />
        <Box
          bg="#f8f9fb"
          css={{
            height: 18,
            borderRadius: 1.2,

            [mq('sm')]: {
              height: 30,
              borderRadius: 2,
            },
          }}
        />
      </Grid>
      <Grid
        bg="#ffffff"
        css={{
          padding: '10px 15px',
          rowGap: 6,

          [mq('sm')]: {
            padding: '16px 24px',
            rowGap: 16,
          },
        }}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Text
            css={{
              fontSize: 9.6,

              [mq('sm')]: {
                fontSize: 16,
              },
            }}
          >
            ลิงก์สาธารณะสู่นโยบายของคุณ
          </Text>
          <Grid
            color="#cccccc"
            columnGap="2px"
            autoFlow="column"
            css={{
              fontSize: 8.4,

              [mq('sm')]: {
                fontSize: 14,
              },
            }}
          >
            <LangSelect
              label="ภาษาไทย"
              lang="th"
              selected={lang}
              onClick={(lang) => {
                setLang(lang)

                logEvent({
                  ga: {
                    category: 'Landing Page-Demo',
                    action: 'Demo-TH',
                  },
                })
              }}
            />
            <Text>|</Text>
            <LangSelect
              label="ภาษาอังกฤษ"
              lang="en"
              selected={lang}
              onClick={(lang) => {
                setLang(lang)

                logEvent({
                  ga: {
                    category: 'Landing Page-Demo',
                    action: 'Demo-EN',
                  },
                })
              }}
            />
          </Grid>
        </Flex>
        <Grid
          border="1px solid #e8e8e8"
          alignItems="center"
          justifyContent="space-between"
          autoFlow="column"
          css={{
            height: 29,
            padding: '0 5px 0 9.6px',
            columnGap: 5,

            [mq('sm')]: {
              height: 48,
              padding: '7px 12px 7px 16px',
              columnGap: 10,
            },
          }}
        >
          <Text
            ref={linkRef}
            flex="1"
            overflow="hidden"
            css={{
              textOverflow: 'ellipsis',
              fontSize: 9.6,
              whiteSpace: 'pre',

              [mq('sm')]: {
                fontSize: 16,
              },
            }}
          >{`https://pdpa.pro/policies/view/${lang}/RrzX71VwGqP23SmxRkaFAofR`}</Text>
          <Button
            bg="#ff7315"
            color="#ffffff"
            rounded="4px"
            css={{
              fontSize: 8.4,
              height: 20,
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem',

              [mq('sm')]: {
                fontSize: 14,
                height: 34,
                paddingLeft: '1rem',
                paddingRight: '1rem',
              },
            }}
            onClick={() => {
              copyToClipboard(linkRef)

              logEvent({
                ga: {
                  category: 'Landing Page-Demo',
                  action: 'Demo-Copy link',
                },
              })
            }}
          >
            คัดลอกลิงก์
          </Button>
        </Grid>
        <Grid
          border="1px solid #e8e8e8"
          bg="#f6f6f6"
          templateRows="max-content 1fr"
          css={{
            padding: '12px 10px',
            height: 226,
            rowGap: 5,
            borderRadius: 4.8,

            [mq('sm')]: {
              padding: '20px 16px',
              height: 376,
              rowGap: 8,
              borderRadius: 8,
            },
          }}
        >
          <Grid columnGap="8px" autoFlow="column">
            {tabs.map(({ button, action }, index) => {
              const style =
                index === selectedIndex ? activeStyle : inactiveStyle
              return (
                <Button
                  key={`button-${index}`}
                  {...style}
                  css={{
                    fontSize: 8.4,
                    height: 25,

                    [mq('sm')]: {
                      fontSize: 14,
                      height: 42,
                    },
                  }}
                  onClick={() => {
                    setSelectedIndex(index)

                    logEvent({
                      ga: {
                        category: 'Landing Page-Demo',
                        action: action,
                      },
                    })
                  }}
                >
                  {button}
                </Button>
              )
            })}
          </Grid>
          <Box overflow="hidden">{selectedTab.content[lang]}</Box>
        </Grid>
        <Text
          textAlign="right"
          fontWeight="300"
          lineHeight="1.93"
          color="#888888"
          css={{
            fontSize: 8.4,

            [mq('sm')]: {
              fontSize: 14,
            },
          }}
        >
          อัปเดตเวอร์ชันล่าสุด 22 ตุลาคม 2563
        </Text>
      </Grid>
    </Flex>
  )
}

export default Interactive
